import { useCallback } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { Rect } from '@view-model/models/common/basic';
import { UserIconMenu } from './UserIconMenu';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { ViewerIcons } from '@view-model/Viewer/ui';
import { NotificationIcon } from './NotificationIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { HelpPageIcon } from './HelpPageIcon';
import { TimerContainer } from './Timer';
import { ViewModelId } from '@schema-common/base';

export type CurrentMenu = 'comments' | 'notifications' | 'none';

type Props = {
    setClientViewBox: (viewBox: Rect) => void;
    setCurrentMenu: (menu: CurrentMenu) => void;
    currentMenu: CurrentMenu;
    showTimer: boolean;
    onOpenTimer(): void;
    onCloseTimer(): void;
    viewModelId: ViewModelId;
};

export const HeaderMenuRight: React.FC<Props> = ({
    setClientViewBox,
    setCurrentMenu,
    currentMenu,
    showTimer,
    onOpenTimer,
    onCloseTimer,
    viewModelId,
}: Props) => {
    const currentUserId = useCurrentUserId();
    const onToggleCommentList = useCallback(
        () => setCurrentMenu(currentMenu === 'comments' ? 'none' : 'comments'),
        [currentMenu, setCurrentMenu]
    );
    const onToggleNotifications = useCallback(
        () => setCurrentMenu(currentMenu === 'notifications' ? 'none' : 'notifications'),
        [currentMenu, setCurrentMenu]
    );

    return (
        <>
            <div className="flex justify-end gap-2">
                <div className="pointer-events-auto">
                    <DarkTooltip
                        tooltip={
                            currentMenu === 'comments'
                                ? 'コメントをしまう | Hide comments'
                                : 'コメントを表示 | Show comments'
                        }
                        placement={'bottom'}
                    >
                        <button
                            className="flex size-12 cursor-pointer items-center justify-center rounded-lg bg-white text-2xl text-gray-700 shadow-md hover:bg-gray-200 focus:outline-none"
                            onClick={onToggleCommentList}
                            aria-label="コメント | Comments"
                        >
                            <FontAwesomeIcon icon={faComments} />
                        </button>
                    </DarkTooltip>
                </div>

                {currentUserId && <ViewerIcons className="pointer-events-auto" setClientViewBox={setClientViewBox} />}

                <NotificationIcon
                    className="pointer-events-auto"
                    showNotifications={currentMenu === 'notifications'}
                    onToggle={onToggleNotifications}
                />

                <HelpPageIcon className="pointer-events-auto" />

                <div className="pointer-events-auto">{currentUserId && <UserIconMenu userId={currentUserId} />}</div>
            </div>
            <TimerContainer
                showTimer={showTimer}
                onOpen={onOpenTimer}
                onClose={onCloseTimer}
                viewModelId={viewModelId}
            />
        </>
    );
};
