import { useCallback, useEffect, useState } from 'react';
import { Explanation, UserOperationModesPanel } from './UserOperationModesPanel';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { UserOperationModes, useUserOperationModes } from '@user/pages/ViewModelPage/UserOperationModesProvider';

type Props = {
    isOpen: boolean;
    onClose(): void;
};

const explanationMap: Explanation = {
    mouse: {
        move: 'ドラッグ＆ドロップで移動',
        select: 'Shift＋ドラッグで範囲選択',
    },
    trackpad: {
        move: 'ドラッグ＆ドロップで移動',
        select: 'Shift＋ドラッグで範囲選択',
    },
};

const explanationSlide: Explanation = {
    mouse: {
        move: '右クリック＋ドラッグで移動',
        select: 'ドラッグ＆ドロップで範囲選択',
    },
    trackpad: {
        move: 'スクロールで移動',
        select: 'ドラッグ＆ドロップで範囲選択',
    },
};

export const UserOperationModesModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
    const { userOperationMode, modeSwitcher } = useUserOperationModes();
    const [selectedMode, setSelectedMode] = useState<UserOperationModes | null>(null);

    useEffect(() => {
        setSelectedMode(userOperationMode);
    }, [userOperationMode]);

    const handleClose = useCallback(() => {
        // 保存しなかった場合、DBの値に戻す
        setSelectedMode(userOperationMode);
        onClose();
    }, [onClose, userOperationMode]);

    const handleSubmit = useCallback(() => {
        if (selectedMode !== null) {
            modeSwitcher(selectedMode);
        }
        onClose();
    }, [modeSwitcher, selectedMode, onClose]);

    const tempModeSwitcher = useCallback(
        (mode: UserOperationModes) => {
            if (mode === selectedMode) return;

            setSelectedMode(selectedMode === 'map' ? 'slide' : 'map');
        },
        [selectedMode]
    );

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            submitButton={
                <Button color="primary" onClick={handleSubmit}>
                    保存 | Done
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={handleClose}>
                    閉じる | Cancel
                </Button>
            }
            title={'操作モードの設定 | Switch user operation mode'}
        >
            <div className="flex flex-row p-5">
                <UserOperationModesPanel
                    title="地図モード"
                    explanation={explanationMap}
                    isSelected={selectedMode === 'map'}
                    mode="map"
                    tempModeSwitcher={tempModeSwitcher}
                />
                <UserOperationModesPanel
                    title="スライドモード"
                    explanation={explanationSlide}
                    isSelected={selectedMode === 'slide'}
                    mode="slide"
                    tempModeSwitcher={tempModeSwitcher}
                />
            </div>
        </GeneralModal>
    );
};
