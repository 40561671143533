import { EditorContent, useEditor, Editor } from '@tiptap/react';
import { Link } from '@tiptap/extension-link';
import { StarterKit } from '@tiptap/starter-kit';
import { Image } from '@tiptap/extension-image';
import { EditorToolbar, TOOLBAR_HEIGHT } from './EditorToolbar';
import { Markdown } from 'tiptap-markdown';
import { classNames } from '@framework/utils';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { DescriptionPanelFontSize } from '../../domain';
import './descriptionEditor.css';

type Props = {
    content: string;
    fontSize: DescriptionPanelFontSize;
    editing: boolean;
    className?: string;
    onBlur(): void;
    onSubmit(value: string): void;
    onFontSizeChange(fontSize: DescriptionPanelFontSize): void;
    onURLModalOpen(): void;
    onImageURLModalOpen(): void;
};

export const DescriptionEditor = forwardRef<Editor, Props>(
    (
        {
            content,
            fontSize,
            editing,
            className,
            onBlur,
            onSubmit,
            onFontSizeChange,
            onURLModalOpen,
            onImageURLModalOpen,
        },
        ref
    ) => {
        const [isMdMode, setIsMdMode] = useState<boolean>(false);
        const [toolbarHeight, setToolbarHeight] = useState<number>(TOOLBAR_HEIGHT);
        const [tempContent, setTempContent] = useState<string>(content);
        const [firedModeChange, setFiredModeChange] = useState<boolean>(false);
        const textareaRef = useRef<HTMLTextAreaElement>(null);
        const editor = useEditor({
            extensions: [
                StarterKit,
                Link,
                Markdown.configure({ transformPastedText: true, breaks: true }),
                Image.configure({
                    inline: true,
                    allowBase64: true,
                }),
            ],
            editorProps: {
                attributes: {
                    class: 'focus:outline-none size-full rounded-lg border border-gray-400 p-4 overflow-y-auto overflow-x-hidden',
                },
            },
            onCreate: ({ editor }) => {
                editor.commands.setContent(tempContent);
            },
            onUpdate: ({ editor }) => {
                setTempContent(editor.storage.markdown.getMarkdown());
            },
            onBlur: ({ event }) => {
                // リッチエディタへの切り替えボタンを押すと切り替わって最初にボタンに対して focusEvent が発火し blur が発火するので、その場合は onBlur を発火させない
                if (firedModeChange) {
                    setFiredModeChange(false);
                    return;
                }
                /*
                 * tiptap の FocusEvent が React.FocusEvent と型が違う上、そのままキャストできないので一度 unknown にキャストする
                 * 定義のされ方が理由で ts は互換性がないと判断するが、実際は互換性があるのでこのようにキャストしても問題はない
                 */
                handleEditorBlur(event as unknown as React.FocusEvent);
            },
        });

        useEffect(() => {
            if (isMdMode) {
                textareaRef.current?.focus();
            } else {
                editor?.commands.focus();
            }
        }, [isMdMode, editor, editing]);

        const handleEditorModeChange = () => {
            setTempContent(editor?.storage.markdown.getMarkdown());
            setIsMdMode(!isMdMode);
            setFiredModeChange(true);
        };

        const handleMdEditorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setTempContent(event.target.value);
            editor?.commands.setContent(tempContent);
        };

        const handleEditorBlur = (e: React.FocusEvent) => {
            // メニューボタンやセレクトボックスをクリックした場合は onBlur を発火させない
            if (e.relatedTarget?.nodeName === 'BUTTON' || e.relatedTarget?.nodeName === 'SELECT') {
                return;
            }
            onSubmit(tempContent);
            onBlur();
        };

        if (!editor) {
            return null;
        }

        if (editor && ref && typeof ref !== 'function') {
            ref.current = editor;
        }

        return (
            <div className={classNames(className)}>
                <EditorToolbar
                    editor={editor}
                    fontSize={fontSize}
                    isMarkdownMode={isMdMode}
                    onModeChange={handleEditorModeChange}
                    onFontSizeChange={onFontSizeChange}
                    onURLModalOpen={onURLModalOpen}
                    onImageURLModalOpen={onImageURLModalOpen}
                    onChangeHeight={setToolbarHeight}
                />
                <div className="w-full" style={{ height: `calc(100% - ${toolbarHeight}px)` }}>
                    {isMdMode ? (
                        <textarea
                            ref={textareaRef}
                            value={tempContent}
                            placeholder={'# タイトル | Title\nテキスト | Text'}
                            className={'size-full resize-none rounded-lg border border-gray-400 p-4 focus:outline-none'}
                            onChange={handleMdEditorChange}
                            onBlur={handleEditorBlur}
                            onFocus={(e) => {
                                const len = e.currentTarget.value.length;
                                e.currentTarget.setSelectionRange(len, len);
                            }}
                        />
                    ) : (
                        <EditorContent editor={editor} className="size-full" />
                    )}
                </div>
            </div>
        );
    }
);

DescriptionEditor.displayName = 'DescriptionEditor';
