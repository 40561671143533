import { useEffect, useMemo } from 'react';
import { pointer, select } from 'd3-selection';
import { zoom as Zoom, ZoomBehavior } from 'd3-zoom';
import { MouseEventButton } from '@view-model/models/common/basic/MouseEventButton';
import { UserOperationModes } from '@user/pages/ViewModelPage/UserOperationModesProvider';
import { zoomIdentity, ZoomTransform } from 'd3';
import { Point } from '@view-model/models/common/basic';
import { ScaleExtent } from '../domain/ScaleExtent';
import { ViewBox } from '@view-model/domain/view-model';

type Props = {
    zoomPanHandlerRef: React.RefObject<SVGGElement>;
    userOperationMode: UserOperationModes;
    scaleExtent: ScaleExtent;
    viewBox: ViewBox;
    onZoom: (t: ZoomTransform) => void;
};

type Extent = [[number, number], [number, number]];

interface WheelEventEx extends WheelEvent {
    readonly wheelDeltaX: number;
    readonly wheelDeltaY: number;
}

interface EventTargetEx extends EventTarget {
    readonly __zoom: ZoomTransform;
}

const translateExtent = [
    [-Infinity, -Infinity],
    [Infinity, Infinity],
];

const getExtent = (viewBox: ViewBox): Extent => {
    const { minX: x, minY: y, width, height } = viewBox;

    return [
        [x, y],
        [x + width, y + height],
    ];
};

const scaled = (transform: ZoomTransform, k: number, scaleExtent: ScaleExtent): ZoomTransform => {
    const k2 = Math.max(scaleExtent.min, Math.min(scaleExtent.max, k));
    return k2 === transform.k ? transform : new ZoomTransform(k2, transform.x, transform.y);
};

const translate = (transform: ZoomTransform, p0: number[], p1: number[]): ZoomTransform => {
    const x = p0[0] - p1[0] * transform.k;
    const y = p0[1] - p1[1] * transform.k;

    return x === transform.x && y === transform.y ? transform : new ZoomTransform(transform.k, x, y);
};

const constrain = (transform: ZoomTransform, extent: Extent): ZoomTransform => {
    const dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0];
    const dx1 = transform.invertX(extent[1][0]) - translateExtent[1][0];
    const dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1];
    const dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];

    return transform.translate(
        dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
        dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
    );
};

export const useZoomPanHandler = (props: Props) => {
    const { zoomPanHandlerRef, userOperationMode, scaleExtent, viewBox, onZoom } = props;

    const zoomPanHandler = useMemo(
        () => getZoomPanHandler(userOperationMode, scaleExtent, onZoom),
        [onZoom, scaleExtent, userOperationMode]
    );

    useEffect(() => {
        const target = zoomPanHandlerRef.current;
        if (!target) return;

        if (userOperationMode === 'slide') {
            // NOTE:
            //
            // 1. trackpadのスクロールによるpanning
            // 2. trackpadのピンチによるzooming
            // 1が実装されていなかったため、d3-zoomのデフォルトの挙動を上書き
            //
            // panningはmiroと同様、スワイプしたのと逆の報告に移動する（逆にする場合は-1を掛ける）
            //
            // TODO:
            //
            // ・マウスホイールによるzoomがpanningと判定されている
            // ・Ctrlキーを押した状態だとマウスホイールでzoomするが、拡大・縮小率をもう少し抑えたい（d3内部でCtrlキー押下の場合に倍率を上げている）
            const zoomOrPan = (eventEx: WheelEventEx) => {
                // ブラウザ自体のzoomが発火しないようにする
                eventEx.preventDefault();

                const currentTransform = (eventEx.currentTarget as EventTargetEx).__zoom;

                if (eventEx.ctrlKey) {
                    // NOTE:
                    //   トラックパッドでピンチのジェスチャを行うと、event.ctrlKeyがtrueになる(非直感的だがブラウザの実装)
                    //   参考: https://medium.com/@auchenberg/detecting-multi-touch-trackpad-gestures-in-javascript-a2505babb10e
                    const mousePointer = pointer(eventEx);
                    const calculatedScale = currentTransform.k - currentTransform.k * eventEx.deltaY * 0.01; // 現在の倍率から変化量を計算
                    const adjustedScale = Math.max(scaleExtent.min, Math.min(scaleExtent.max, calculatedScale));
                    // console.log(`[zooming] adjustedScale: ${adjustedScale}`);

                    const diff = adjustedScale - currentTransform.k;
                    if (diff === 0) {
                        // zoom scale not changed
                        return;
                    }

                    const invertedMousePointer = [mousePointer, currentTransform.invert(mousePointer)];

                    const translatedTransform = translate(
                        scaled(currentTransform, adjustedScale, scaleExtent),
                        invertedMousePointer[0],
                        invertedMousePointer[1]
                    );
                    const constrainedTransform = constrain(translatedTransform, getExtent(viewBox));

                    zoomPanHandler.transform(select(target), constrainedTransform, mousePointer);
                } else {
                    // panning
                    // 移動比率を倍率に応じて変化させる（拡大するほど遅くする）
                    const panFactor = 0.85 / currentTransform.k;
                    // console.log(
                    //     `[panning] x: ${eventEx.wheelDeltaX} y: ${eventEx.wheelDeltaY} panFactor: ${panFactor}`
                    // );

                    const dX = eventEx.wheelDeltaX * panFactor;
                    const dY = eventEx.wheelDeltaY * panFactor;

                    zoomPanHandler.translateBy(select(target), dX, dY);
                }
            };

            select(target).call(zoomPanHandler).on('wheel.zoom', zoomOrPan);
        } else {
            // userOperationMode: map
            select(target).call(zoomPanHandler);
        }

        return () => {
            select(target).on('zoom', null);
            select(target).on('wheel.zoom', null);
        };
    }, [scaleExtent, scaleExtent.max, scaleExtent.min, userOperationMode, viewBox, zoomPanHandler, zoomPanHandlerRef]);

    const resetZoom = (centerPosition: Point, scale: number) => {
        if (!zoomPanHandlerRef.current) {
            return;
        }

        select(zoomPanHandlerRef.current).call(
            zoomPanHandler.transform,
            zoomIdentity.translate(-1 * centerPosition.x * scale, -1 * centerPosition.y * scale).scale(scale)
        );
    };

    const zoom = (scale: number) => {
        if (!zoomPanHandlerRef.current) {
            return;
        }

        select(zoomPanHandlerRef.current).call(zoomPanHandler.scaleBy, scale);
    };

    return {
        resetZoom,
        zoom,
    };
};

const getZoomPanHandler = (
    userOperationMode: UserOperationModes,
    scaleExtent: ScaleExtent,
    onZoom: (t: ZoomTransform) => void
): ZoomBehavior<SVGGElement, unknown> => {
    //
    if (userOperationMode === 'map') {
        return Zoom<SVGGElement, unknown>()
            .on('zoom', (event) => {
                onZoom(event.transform);
            })
            .scaleExtent([scaleExtent.min, scaleExtent.max]);
    } else {
        // userOperationMode: slide
        return Zoom<SVGGElement, unknown>()
            .filter((event: MouseEvent | WheelEvent) => {
                if (event instanceof MouseEvent && event.buttons === MouseEventButton.SecondaryButton) {
                    // スライドモードの場合は右クリック＆ドラッグによるpanningを実施
                    return true;
                }
                if (event instanceof WheelEvent && event.buttons === MouseEventButton.NoButton) {
                    // ホイールによるzoomも許可
                    return true;
                }
                return false;
            })
            .on('zoom', (event) => {
                const source = event.sourceEvent;

                if (source && source.buttons === MouseEventButton.SecondaryButton) {
                    // 右クリックでコンテキストメニューを出さないようにする
                    source.preventDefault();
                }
                onZoom(event.transform);
            })
            .scaleExtent([scaleExtent.min, scaleExtent.max]);
    }
};
