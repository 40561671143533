import { UserOperationModesModal } from '@view-model/models/sticky/userOperationModes/components/UserOperationModesModal';
import { Button } from './Button';
import { faExpand, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

type Props = {
    onZoomIn: () => void;
    onZoomOut: () => void;
    onZoomReset: () => void;
};

export const ZoomButtons: React.FC<Props> = ({ onZoomIn, onZoomOut, onZoomReset }: Props) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    // 操作モード設定ダイアログ関連のハンドラ
    const handleOpenModal = useCallback(() => setModalOpen(true), []);
    const handleCloseModal = useCallback(() => setModalOpen(false), []);

    return (
        <>
            <div className="flex gap-2">
                <Button
                    caption="操作モード"
                    tooltip="操作モードの設定 | Switch user operation mode"
                    className="rounded-lg"
                    onClick={handleOpenModal}
                />
                <div className="flex gap-1">
                    <Button icon={faMinus} tooltip="縮小 | Zoom out" className="rounded-l-lg" onClick={onZoomOut} />
                    <Button icon={faPlus} tooltip="拡大 | Zoom in" className="rounded-r-lg" onClick={onZoomIn} />
                </div>
                <Button
                    icon={faExpand}
                    tooltip="ビューモデル全体を見る | Zoom to fit"
                    className="rounded-lg"
                    onClick={onZoomReset}
                />
            </div>
            <UserOperationModesModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </>
    );
};
