import { useCallback, useEffect, useRef, useState } from 'react';
import { Point, Size } from '@view-model/models/common/basic';
import { Markdown } from '@framework/ui/elements/Markdown';
import { useD3DblClickCallback, useTextSelectable } from '@view-model/models/common/hooks';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';
import { DescriptionPanelFontSize } from '../domain';
import { FontSizeSubMenu } from '@view-model/models/framework/text';
import { DescriptionEditor } from './DescriptionEditor';
import { DescriptionEditorSetUrlModal } from './DescriptionEditorSetUrlModal';
import { DescriptionEditorSetImageUrlModal } from './DescriptionEditorSetImageUrlModal';
import { Editor } from '@tiptap/react';
import { useEditingDescriptionPanel } from '@view-model/models/sticky/DescriptionPanel/hook/EditingDescriptionPanelContext';

type Props = {
    editing: boolean;
    content: string;
    panelId: string;
    size: Size;
    fontSize: DescriptionPanelFontSize;
    isSelected: boolean;
    readonly: boolean;
    onClick(): void;
    onStartEdit(text: string): void;
    onEdit(text: string): void;
    onEndEdit(): void;
    onDblClick(): void;
    onBlur(): void;
    onFontSizeChange(fontSize: DescriptionPanelFontSize): void;
};

export const DescriptionContent: React.FC<Props> = ({
    editing,
    content,
    panelId,
    size,
    fontSize,
    isSelected,
    readonly,
    onClick,
    onStartEdit,
    onEdit,
    onEndEdit,
    onDblClick,
    onBlur,
    onFontSizeChange,
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<Editor>(null);
    const prevEditing = usePrevious(editing);
    const [isUrlModalOpen, setIsUrlModalOpen] = useState<boolean>(false);
    const [isImageUrlModalOpen, setIsImageUrlModalOpen] = useState<boolean>(false);
    const { setEditingPanelId } = useEditingDescriptionPanel();

    // fontSizeMenuが説明パネル全体の左上からどれだけずれているか
    const fontSizeMenuPoint = new Point(32, 48);
    // fontSizeMenuの大きさ
    const fontSizeMenuSize = 48;
    // 編集中の場合 FontSizeMenu がでないので、その分だけ Container をずらす
    const containerYaxis = editing ? fontSizeMenuPoint.y : fontSizeMenuSize + fontSizeMenuPoint.y;

    // テキストエリア等の高さを決めている
    // (fontSizeMenu自体のずれ + fontSizeMenuの大きさ) 分だけ小さくなっている
    const textContentHeight = editing
        ? size.height - fontSizeMenuPoint.y
        : size.height - (fontSizeMenuPoint.y + fontSizeMenuSize);

    // 対象の説明パネルを選択している場合には、テキスト選択を可能にする
    useTextSelectable(containerRef, true);

    // ダブルクリックによる編集開始
    useD3DblClickCallback(
        containerRef,
        useCallback(() => onDblClick(), [onDblClick]),
        true
    );

    // 編集開始時のコールバック、及び、テキストエリアへのフォーカス & テキスト全選択
    useEffect(() => {
        if (prevEditing !== editing && editing) {
            setEditingPanelId(panelId);
            onStartEdit(content);
        }
    }, [content, editing, onStartEdit, panelId, prevEditing, setEditingPanelId]);

    // 編集完了時のコールバック
    useEffect(() => {
        if (prevEditing !== editing && prevEditing) {
            setEditingPanelId(null);
            onEndEdit();
        }
    }, [content, editing, onEndEdit, prevEditing, setEditingPanelId]);

    const handleOnURLModalClose = () => {
        setIsUrlModalOpen(false);
    };

    const handleOnURLModalOpen = () => {
        setIsUrlModalOpen(true);
    };

    const handleOnSubmit = (value: string) => {
        onEdit(value);
    };

    const handleOnImageURLModalClose = () => {
        setIsImageUrlModalOpen(false);
    };

    const handleOnImageURLModalOpen = () => {
        setIsImageUrlModalOpen(true);
    };
    return (
        <>
            <style>
                {`
                .description-panel-${panelId} {
                    font-size: ${DescriptionPanelFontSize.toPixelSize(fontSize)}px;

                    pre code {
                        word-break: break-all;
                        white-space: pre-wrap;
                    }
                }
                `}
            </style>
            <foreignObject
                width={size.width}
                height={textContentHeight}
                onClick={onClick}
                // 説明パネル中のテキストエリア等の開始位置をずらす
                transform={`translate(0, ${containerYaxis})`}
            >
                <div
                    ref={containerRef}
                    className="size-full rounded-2xl px-8 pb-8 pt-4"
                    style={{ fontSize: DescriptionPanelFontSize.toPixelSize(fontSize) }}
                >
                    <DescriptionEditor
                        ref={editorRef}
                        content={content}
                        fontSize={fontSize}
                        editing={editing}
                        onSubmit={handleOnSubmit}
                        onBlur={onBlur}
                        onURLModalOpen={handleOnURLModalOpen}
                        onImageURLModalOpen={handleOnImageURLModalOpen}
                        onFontSizeChange={onFontSizeChange}
                        // eslint-disable-next-line tailwindcss/no-custom-classname
                        className={editing ? `description-panel-${panelId} size-full resize-none bg-white` : 'hidden'}
                    />

                    {!editing && (
                        <>
                            {/* 選択状態かつ説明が空文字列の場合には、プレースホルダテキストを薄文字で表示する */}
                            {isSelected && content === '' ? (
                                <Markdown
                                    // eslint-disable-next-line tailwindcss/no-custom-classname
                                    className={`description-panel-${panelId} markdown-body h-full text-gray-500`}
                                    source={'# タイトル | Title\nテキスト | Text'}
                                />
                            ) : (
                                <Markdown
                                    // eslint-disable-next-line tailwindcss/no-custom-classname
                                    className={`description-panel-${panelId} markdown-body h-full [&_img]:w-full`}
                                    source={content}
                                />
                            )}
                        </>
                    )}
                </div>
            </foreignObject>
            {isSelected && !editing && !readonly && (
                <g transform={fontSizeMenuPoint.toSVGTranslate()}>
                    {DescriptionPanelFontSize.values().map((s, i) => (
                        <g key={s} transform={`translate(${fontSizeMenuSize * i}, 0)`}>
                            <FontSizeSubMenu
                                fontSize={s}
                                menuSize={fontSizeMenuSize}
                                isSelected={s === fontSize}
                                onClick={() => onFontSizeChange(s)}
                            />
                        </g>
                    ))}
                    <rect
                        className="stroke-gray-500"
                        height={fontSizeMenuSize}
                        width={fontSizeMenuSize * 3}
                        strokeWidth={2}
                        pointerEvents="none"
                        fill="transparent"
                    />
                </g>
            )}
            <DescriptionEditorSetUrlModal
                editor={editorRef.current}
                isOpen={isUrlModalOpen}
                onClose={handleOnURLModalClose}
            />
            <DescriptionEditorSetImageUrlModal
                editor={editorRef.current}
                isOpen={isImageUrlModalOpen}
                onClose={handleOnImageURLModalClose}
            />
        </>
    );
};
