import { useEffect, useState } from 'react';
import { ViewModelEntity } from './ViewModelEntity';
import { ObjectRepository, RTDBPath, TextRepository } from '@framework/repository';
import { WorkspaceEntity, WorkspaceSetting } from '@workspace/domain/workspace';
import { canReadViewModel } from './canReadViewModel';
import { isViewModelSharingUserRoleType, ViewModelSharingUserRoleType } from './vo/ViewModelSharingUserRoleType';
import { UserId, ViewModelId, isValidId } from '@schema-common/base';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { useCurrentUserId } from '@framework/auth';

type Result = {
    workspace: WorkspaceEntity | null;
    viewModel: ViewModelEntity | null;
    loading: boolean;
};

const Loading: Result = { workspace: null, viewModel: null, loading: true };
const NotFound: Result = { workspace: null, viewModel: null, loading: false };

type Cleanup = () => void;

const fetchViewModelEntity = async (
    viewModelId: ViewModelId,
    currentUserId: UserId | null,
    setState: (state: Result | ((prev: Result) => Result)) => void
): Promise<Cleanup> => {
    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、
     * Not Found として取り扱う。
     */
    if (!isValidId(viewModelId)) {
        setState(NotFound);
        return () => void 0;
    }

    // ローディング状態に遷移させる
    setState(Loading);

    const workspaceId = await new TextRepository(RTDBPath.ViewModel.viewModelWorkspaceIdPath(viewModelId)).get();

    // ビューモデルの workspaceId プロパティの値が見つからない場合には Not Found 扱い
    if (!workspaceId) {
        setState(NotFound);
        return () => void 0;
    }

    const role = currentUserId
        ? await new TextRepository<WorkspaceMemberRoleJSON>(
              RTDBPath.Workspace.memberRolePath(workspaceId, currentUserId)
          ).get()
        : null;
    const setting = await new ObjectRepository(WorkspaceSetting, RTDBPath.Workspace.settingPath(workspaceId)).get();

    const viewModelTrashedAt = await new TextRepository(RTDBPath.ViewModel.trashedAtPath(viewModelId)).get();
    const viewModelSharingUserRole = await new TextRepository(
        RTDBPath.ViewModel.sharingUserRolePath(viewModelId)
    ).get();

    // ワークスペースの設定が存在しない
    // ビューモデルの共有設定がない
    // ビューモデルの取得権限がない
    // 上記のいずれかに該当する場合はNot Foundにする
    if (
        setting === null ||
        viewModelSharingUserRole === null ||
        !isViewModelSharingUserRoleType(viewModelSharingUserRole) ||
        !canReadViewModel(
            setting,
            role,
            viewModelTrashedAt,
            ViewModelSharingUserRoleType.load(viewModelSharingUserRole)
        )
    ) {
        setState(NotFound);
        return () => void 0;
    }

    const wsRepo = new ObjectRepository(WorkspaceEntity, RTDBPath.Workspace.workspacePath(workspaceId));
    const vmRepo = new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId));

    const [workspace, viewModel] = await Promise.all([wsRepo.get(), vmRepo.get()]);

    // ワークスペース、ビューモデルの一方が null の場合には、両方を null にしてロード完了
    if (workspace === null && viewModel === null) {
        setState(NotFound);
        return () => void 0;
    }

    // 最初の取得完了のステート更新を行ってから、変更監視を始める
    setState({ workspace, viewModel, loading: false });

    wsRepo.addListener((workspace) => setState((prev) => ({ ...prev, workspace })));

    vmRepo.addListener((viewModel) =>
        setState(({ viewModel: prev, ...rest }) =>
            // ビューモデルの updatedAt 以外が一致しているならば、ビューモデル・エンティティはそのまま更新しない。
            // (updatedAt 以外に変更があった場合には、変更監視コールバックの新しいビューモデル・エンティティに置き換える)
            viewModel && prev && viewModel.isEqualExcludingUpdatedAt(prev)
                ? { ...rest, viewModel: prev }
                : { ...rest, viewModel }
        )
    );

    return () => {
        wsRepo.removeListener();
        vmRepo.removeListener();
    };
};

export const useViewModelEntity = (viewModelId: ViewModelId): Result => {
    const [result, setResult] = useState<Result>(Loading);

    const currentUserId = useCurrentUserId();

    useEffect(() => {
        let cleanup: null | (() => void) = null;

        // ビューモデルとワークスペースの読み取り可否の権限チェックを行なってから、エンティティの取得・監視を行う。
        fetchViewModelEntity(viewModelId, currentUserId, setResult).then((f) => (cleanup = f));

        return () => {
            cleanup?.();
        };
    }, [currentUserId, viewModelId]);

    return result;
};
