import { Key } from '@framework/domain';
import { ReleaseToggleNameTypes } from '@schema-common/app';
import {
    ClientId,
    DescriptionPanelId,
    FolderId,
    GroupId,
    GroupMemberInvitationId,
    InvitationMailDataId,
    LinkId,
    ModelCommentThreadId,
    ModelId,
    NodeId,
    OperatorUserId,
    StickyZoneId,
    ElementId,
    UserId,
    ViewId,
    ViewModelId,
    WorkspaceId,
    AnnouncementId,
    ConsistencyLinkId,
    MigrationJobId,
    MigrationTaskId,
    ViewModelAssetId,
} from '@schema-common/base';
import { UserKey } from '@user/domain';
import { StickyZoneKey } from '@view-model/domain/key';
import { ReactionType } from '@view-model/models/sticky/NodeReaction';
import { WorkspaceKey } from '@workspace/domain/key';
import { isString } from 'lodash';

export type DBPath = string;

export namespace RTDBPath {
    const join = (root: DBPath, ...subPaths: string[]): DBPath => {
        return [root, ...subPaths].join('/');
    };

    // Firebase Realtime Database が提供する情報
    // https://firebase.google.com/docs/database/web/offline-capabilities
    export namespace Firebase {
        export const serverTimeOffsetPath = () => '.info/serverTimeOffset';

        export const connectedPath = () => '.info/connected';
    }

    // データマイグレーション基盤
    export namespace DataMigration {
        const DataMigrationPath = 'data-migration';
        export const dataMigrationPath = (): DBPath => DataMigrationPath;

        export const jobsPath = (): DBPath => join(DataMigrationPath, 'jobs');
        export const jobPath = (jobId: MigrationJobId): DBPath => join(jobsPath(), jobId);
        export const jobFinishedPath = (jobId: MigrationJobId): DBPath => join(jobPath(jobId), 'finished');

        export const taskCreatingStatusPath = (jobId: MigrationJobId): DBPath =>
            join(DataMigrationPath, 'task-creating-status', jobId);

        export const taskCreatingTriggersPath = (jobId: MigrationJobId): DBPath =>
            join(DataMigrationPath, 'task-creating-triggers', jobId);
        export const taskCreatingTriggerPath = (jobId: MigrationJobId, triggerId: string): DBPath =>
            join(taskCreatingTriggersPath(jobId), triggerId);
        export const taskCreatingTriggerInvokedAtPath = (jobId: MigrationJobId, triggerId: string): DBPath =>
            join(taskCreatingTriggerPath(jobId, triggerId), 'invokedAt');

        export const tasksPath = (jobId: MigrationJobId): DBPath => join(DataMigrationPath, 'tasks', jobId);
        export const initialTasksPath = (jobId: MigrationJobId): DBPath => join(tasksPath(jobId), 'initial');
        export const initialTaskPath = (jobId: MigrationJobId, taskId: MigrationTaskId): DBPath =>
            join(initialTasksPath(jobId), taskId);
        export const alreadyAppliedTasksPath = (jobId: MigrationJobId): DBPath =>
            join(tasksPath(jobId), 'already-applied');
        export const alreadyAppliedTaskPath = (jobId: MigrationJobId, taskId: MigrationTaskId): DBPath =>
            join(alreadyAppliedTasksPath(jobId), taskId);
        export const appliedTasksPath = (jobId: MigrationJobId): DBPath => join(tasksPath(jobId), 'applied');
        export const appliedTaskPath = (jobId: MigrationJobId, taskId: MigrationTaskId): DBPath =>
            join(appliedTasksPath(jobId), taskId);
        export const failedTasksPath = (jobId: MigrationJobId): DBPath => join(tasksPath(jobId), 'failed');
        export const failedTaskPath = (jobId: MigrationJobId, taskId: MigrationTaskId): DBPath =>
            join(failedTasksPath(jobId), taskId);

        export const taskInvokedAtPath = (jobId: MigrationJobId, taskId: MigrationTaskId): DBPath =>
            join(initialTaskPath(jobId, taskId), 'invokedAt');

        export const changeSetsPath = (jobId: MigrationJobId): DBPath =>
            join(DataMigrationPath, 'task-change-sets', jobId);
        export const changeSetPath = (jobId: MigrationJobId, taskId: MigrationTaskId): DBPath =>
            join(changeSetsPath(jobId), taskId);

        // パフォーマンステスト用データ
        export namespace PerformanceTestData {
            const PerformanceTestDataPath = join(DataMigrationPath, 'performance-test-data');
            export const performanceTestDataPath = (): DBPath => PerformanceTestDataPath;
            export const performanceTestDataItemPath = (id: string): DBPath => join(PerformanceTestDataPath, id);
        }
    }

    export namespace ActionLog {
        const ActionLogPath = 'action-log';

        export const actionEventLogsPath = (): DBPath => join(ActionLogPath, 'action-event-logs');
        export const errorRecordsPath = (): DBPath => join(ActionLogPath, 'error-records');
    }

    export namespace App {
        const AppPath = 'levii-balus-app';

        const userId2key = (userId: UserId): string =>
            isString(userId) && userId.startsWith('{') && userId.endsWith('}')
                ? userId
                : UserKey.buildFromId(userId).toString();

        const workspaceId2key = (workspaceId: WorkspaceId): string =>
            isString(workspaceId) && workspaceId.startsWith('{') && workspaceId.endsWith('}')
                ? workspaceId
                : WorkspaceKey.buildFromID(workspaceId).toString();

        export const appPath = (): DBPath => AppPath;

        export namespace ApplicationConfig {
            export const applicationConfigPath = (): DBPath => join(AppPath, 'application-config');
            export const requiredVersionPath = (): DBPath => join(applicationConfigPath(), 'requiredVersion');
            export const releaseTogglePath = (toggleName: ReleaseToggleNameTypes): DBPath =>
                join(applicationConfigPath(), 'release-toggles', toggleName);

            export const maintenanceStartAtPath = (): DBPath => join(applicationConfigPath(), 'maintenanceStartAt');

            export const allVersionsPath = (): DBPath => join(applicationConfigPath(), 'versions');
            export const versionsPath = (hostname: string): DBPath =>
                // パス中に '.' は利用できないので '-DOT-' に置換する
                join(allVersionsPath(), hostname.replace(/\./g, '-DOT-'));
            export const versionPath = (hostname: string, version: number): DBPath =>
                join(versionsPath(hostname), `${version}`);
            export const versionActivePath = (hostname: string, version: number): DBPath =>
                join(versionPath(hostname, version), 'active');
        }

        export namespace Admin {
            const AppAdminPath = join(AppPath, 'admin');

            export const operatorUsersPath = (): DBPath => join(AppAdminPath, 'operator-users');

            export const operatorUserPath = (uid: OperatorUserId): DBPath => join(operatorUsersPath(), uid);

            export const operatorUserStatusPath = (uid: OperatorUserId): DBPath =>
                join(operatorUserPath(uid), 'status');
        }

        export namespace Notification {
            const NotificationPath = join(AppPath, 'notification');

            export const announcementsPath = (): DBPath => join(NotificationPath, 'announcements');
            export const announcementPath = (id: AnnouncementId): DBPath => join(announcementsPath(), id);

            export const announcementLastViewTimePath = (userId: UserId): DBPath =>
                join(NotificationPath, 'announcement-last-viewed-time', userId);

            export const bannerAnnouncementPath = (): DBPath => join(NotificationPath, 'banner-announcement');
        }

        export namespace User {
            const UserPath = join(AppPath, 'user');

            export const activeClientSessionsPath = (): DBPath => join(UserPath, 'active-client-sessions');
            export const activeClientSessionPath = (clientId: ClientId): DBPath =>
                join(activeClientSessionsPath(), clientId);
            export const activeClientSessionStartAtPath = (clientId: ClientId): DBPath =>
                join(activeClientSessionPath(clientId), 'startAt');

            export const activeAuthSessionsPath = (): DBPath => join(UserPath, 'active-auth-sessions');
            export const activeAuthSessionPath = (sessionId: string): DBPath =>
                join(activeAuthSessionsPath(), sessionId);
            export const activeAuthSessionCurrentIpPath = (sessionId: string): DBPath =>
                join(activeAuthSessionPath(sessionId), 'currentIp');

            export const publicProfilePath = (userId: UserId): DBPath => join(UserPath, 'public-profiles', userId);

            export const userEntitiesPath = (): DBPath => join(UserPath, 'entities');
            export const userEntityPath = (userId: UserId): DBPath => join(userEntitiesPath(), userId);
        }

        export namespace Group {
            const GroupInfoPath = join(AppPath, 'group');

            export const groupEntitiesPath = (): DBPath => join(GroupInfoPath, 'entities');

            export const groupEntityPath = (groupId: GroupId): DBPath => join(groupEntitiesPath(), groupId);

            export const memberInvitationsPath = (groupId: GroupId): DBPath =>
                join(GroupInfoPath, 'member-invitations', groupId);

            export const memberInvitationPath = (groupId: GroupId, invitationId: GroupMemberInvitationId): DBPath =>
                join(memberInvitationsPath(groupId), invitationId);

            export const memberInvitationAcceptPath = (groupId: GroupId, userId: UserId): DBPath =>
                join(GroupInfoPath, 'member-invitation-accepts', groupId, userId, 'invitationId');

            export const memberRolesPath = (groupId: GroupId): DBPath => join(GroupInfoPath, 'member-roles', groupId);

            export const memberRolePath = (groupId: GroupId, userId: UserId): DBPath =>
                join(memberRolesPath(groupId), userId);

            export const settingPath = (groupId: GroupId) => join(groupEntityPath(groupId), 'setting');

            export const settingAvailableSignUpMethodsPath = (groupId: GroupId) =>
                join(settingPath(groupId), 'availableSignUpMethods');

            export const multiFactorAuthRequiredPath = (groupId: GroupId): DBPath =>
                join(GroupInfoPath, 'multi-factor-auth-required', groupId);

            export const internalPublicWorkspacesPath = (groupId: GroupId): DBPath =>
                join(GroupInfoPath, 'internal-public-workspaces', groupId);

            export const internalPublicWorkspacePath = (groupId: GroupId, workspaceId: WorkspaceId): DBPath =>
                join(internalPublicWorkspacesPath(groupId), workspaceId);

            export const groupLicensesPath = (): DBPath => join(GroupInfoPath, 'licenses');

            export const groupLicensePath = (groupId: GroupId) => join(groupLicensesPath(), groupId);

            export const groupLicenseInternalNotePath = (groupId: GroupId) =>
                join(GroupInfoPath, 'license-internal-notes', groupId);

            export const groupBookmarkViewModelsPath = (groupId: GroupId, userId: UserId) =>
                join(GroupInfoPath, 'bookmark-view-models', groupId, userId);

            /**
             * あるユーザが所属しているグループIDの逆引きインデックスを返す
             * @param userId
             * @returns
             */
            export const assignedGroupIndexPath = (userId: UserId) =>
                join(GroupInfoPath, 'assigned-group-index', userId);

            export const assignedGroupIndexValuePath = (userId: UserId, groupId: GroupId) =>
                join(assignedGroupIndexPath(userId), groupId);

            export const workspaceIndexPath = (groupId: GroupId) => join(GroupInfoPath, 'workspace-index', groupId);
            export const workspaceIndexValuePath = (groupId: GroupId, workspaceId: WorkspaceId) =>
                join(workspaceIndexPath(groupId), workspaceId);

            export const groupConsoleUsingPath = (sessionId: string) =>
                join(GroupInfoPath, 'group-console-using', sessionId);

            export const ipRestrictionPath = (groupId: GroupId) => join(GroupInfoPath, 'ip-restrictions', groupId);

            export const ipRestrictionConfigurationPath = (groupId: GroupId) =>
                join(ipRestrictionPath(groupId), 'configuration');

            export const ipRestrictionEnabledPath = (groupId: GroupId) => join(ipRestrictionPath(groupId), 'enabled');
        }

        export namespace Mail {
            const MailPath = join(AppPath, 'mail');

            export const groupInvitationMailDataPath = (groupId: GroupId) =>
                join(MailPath, 'group', groupId, 'invitation-mail-data');

            export const groupInvitationMailDataSendPath = (groupId: GroupId, mailDataId: InvitationMailDataId) =>
                join(groupInvitationMailDataPath(groupId), mailDataId, 'send');
        }

        export namespace Workspace {
            const WorkspacesPath = join(AppPath, 'workspaces');
            const WorkspaceInfoPath = join(AppPath, 'workspace');

            export const workspacesPath = () => WorkspacesPath;

            export const workspacePath = (workspaceId: WorkspaceId): DBPath =>
                join(WorkspacesPath, workspaceId2key(workspaceId));

            export const workspaceNamePath = (workspaceId: WorkspaceId): DBPath =>
                join(WorkspacesPath, workspaceId2key(workspaceId), 'name');

            export const workspaceImageUrlPath = (workspaceId: WorkspaceId): DBPath =>
                join(WorkspacesPath, workspaceId2key(workspaceId), 'imageUrl');

            export const memberRolesPath = (workspaceId: WorkspaceId): DBPath =>
                join(workspacePath(workspaceId), 'members');

            export const memberRolePath = (workspaceId: WorkspaceId, userId: UserId): DBPath =>
                join(memberRolesPath(workspaceId), userId2key(userId));

            export const settingPath = (workspaceId: string): DBPath => join(workspacePath(workspaceId), 'setting');

            export const rootFolderTreePath = (workspaceId: WorkspaceId): DBPath =>
                join(WorkspaceInfoPath, 'root-folder-trees', workspaceId);

            export const folderPath = (folderId: FolderId): DBPath => join(WorkspaceInfoPath, 'folders', folderId);

            /**
             * あるユーザが所属しているワークスペースIDの逆引きインデックスを返す
             * @param userId
             * @param groupId
             * @returns
             */
            export const assignedGroupWorkspaceIndexPath = (userId: UserId, groupId: GroupId): DBPath =>
                join(WorkspaceInfoPath, 'assigned-group-workspace-index', userId, groupId);

            export const assignedGroupWorkspaceIndexValuePath = (
                userId: UserId,
                groupId: GroupId,
                workspaceId: WorkspaceId
            ): DBPath => join(assignedGroupWorkspaceIndexPath(userId, groupId), workspaceId);
        }

        export namespace ViewModel {
            //------------------------------------------------------------------------------
            const ViewModelNamespace = join(AppPath, 'view-model');
            // ------------------------------------------------------------------------------

            export const viewModelsPath = (): DBPath => join(ViewModelNamespace, 'entities');

            export const viewModelPath = (viewModelId: ViewModelId): DBPath => join(viewModelsPath(), viewModelId);

            export const viewModelContentPath = (viewModelId: ViewModelId): DBPath =>
                join(ViewModelNamespace, 'contents', viewModelId);

            export const updatedAtPath = (viewModelId: ViewModelId): DBPath =>
                join(viewModelPath(viewModelId), 'updatedAt');

            export const trashedAtPath = (viewModelId: ViewModelId): DBPath =>
                join(viewModelPath(viewModelId), 'trashedAt');

            export const sharingUserRolePath = (viewModelId: ViewModelId): DBPath =>
                join(viewModelPath(viewModelId), 'sharingUserRole');

            export const viewModelWorkspaceIdPath = (viewModelId: ViewModelId): DBPath =>
                join(viewModelPath(viewModelId), 'workspaceId');

            export const viewModelGroupIdPath = (viewModelId: ViewModelId): DBPath =>
                join(viewModelPath(viewModelId), 'groupId');

            export const timerPath = (viewModelId: ViewModelId): DBPath =>
                join(ViewModelNamespace, 'timer', viewModelId);

            export namespace ConsistencyLink {
                export const linksPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelContentPath(viewModelId), 'consistency-links');

                export const linkPath = (viewModelId: ViewModelId, consistencyLinkId: ConsistencyLinkId): DBPath =>
                    join(linksPath(viewModelId), consistencyLinkId);
            }

            export namespace View {
                export const viewsPath = (viewModelId: ViewModelId) => join(viewModelContentPath(viewModelId), 'views');

                export const viewPath = (viewModelId: ViewModelId, viewId: ViewId) =>
                    join(viewsPath(viewModelId), viewId);

                export const namePath = (viewModelId: ViewModelId, viewId: ViewId) =>
                    join(viewPath(viewModelId, viewId), 'name');

                export const sizePath = (viewModelId: ViewModelId, viewId: ViewId) =>
                    join(viewPath(viewModelId, viewId), 'size');

                export const settingPath = (viewModelId: ViewModelId, viewId: ViewId) =>
                    join(viewPath(viewModelId, viewId), 'setting');

                export const themeColorPath = (viewModelId: ViewModelId, viewId: ViewId) =>
                    join(viewPath(viewModelId, viewId), 'themeColor');

                export const positionsPath = (viewModelId: ViewModelId) =>
                    join(viewModelContentPath(viewModelId), 'view-positions');

                export const positionPath = (viewModelId: ViewModelId, viewId: ViewId) =>
                    join(positionsPath(viewModelId), viewId);
            }

            export namespace Model {
                export const modelsPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelContentPath(viewModelId), 'models');

                export const modelPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                    join(modelsPath(viewModelId), modelId);

                const modelContentsPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelContentPath(viewModelId), 'model-contents');

                export const modelContentPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                    join(modelContentsPath(viewModelId), modelId);

                export const displayOrderPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                    join(modelContentPath(viewModelId, modelId), 'display-order');

                export namespace Comment {
                    export const threadsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'model-comment-threads');

                    export const threadPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        threadId: ModelCommentThreadId
                    ): DBPath => join(threadsPath(viewModelId, modelId), threadId);

                    export const positionsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'model-comment-positions');
                }

                export namespace Node {
                    export const nodesPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'nodes');

                    export const nodePath = (viewModelId: ViewModelId, modelId: ModelId, nodeId: NodeId): DBPath =>
                        join(nodesPath(viewModelId, modelId), nodeId);

                    export const nodeNamePath = (viewModelId: ViewModelId, modelId: ModelId, nodeId: NodeId): DBPath =>
                        join(nodePath(viewModelId, modelId, nodeId), 'name');

                    export const nodeStylePath = (viewModelId: ViewModelId, modelId: ModelId, nodeId: NodeId): DBPath =>
                        join(nodePath(viewModelId, modelId, nodeId), 'style');

                    export const nodeUrlPath = (viewModelId: ViewModelId, modelId: ModelId, nodeId: NodeId): DBPath =>
                        join(nodePath(viewModelId, modelId, nodeId), 'url');

                    export const descriptionsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'node-descriptions');

                    export const descriptionPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        elementId: ElementId
                    ): DBPath => join(descriptionsPath(viewModelId, modelId), elementId);

                    export const reactionPath = (viewModelId: ViewModelId, modelId: ModelId, nodeId: NodeId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'node-reactions', nodeId);

                    export const reactionItemPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        nodeId: NodeId,
                        reactionType: ReactionType,
                        userId: UserId
                    ): DBPath => join(reactionPath(viewModelId, modelId, nodeId), reactionType, userId);
                }

                export namespace Link {
                    export const linksPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'links');

                    export const linkPath = (viewModelId: ViewModelId, modelId: ModelId, linkId: LinkId): DBPath =>
                        join(linksPath(viewModelId, modelId), linkId);
                }

                export namespace Zone {
                    export const zonesPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'sticky-zones');

                    export const zonePath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        zoneKey: StickyZoneKey
                    ): DBPath => join(zonesPath(viewModelId, modelId), zoneKey.toString());

                    export const zoneTextPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        zoneKey: StickyZoneKey
                    ): DBPath => join(zonePath(viewModelId, modelId, zoneKey), 'text');

                    export const sizePath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        zoneKey: StickyZoneKey
                    ): DBPath => join(zonePath(viewModelId, modelId, zoneKey), 'size');

                    export const positionsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'zone-positions');

                    export const positionPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        zoneId: StickyZoneId
                    ): DBPath => join(positionsPath(viewModelId, modelId), zoneId);

                    export const descriptionsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'zone-descriptions');

                    export const descriptionPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        elementId: ElementId
                    ): DBPath => join(descriptionsPath(viewModelId, modelId), elementId);
                }

                export namespace DescriptionPanel {
                    export const panelsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'description-panels');

                    export const panelPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        panelId: DescriptionPanelId
                    ): DBPath => join(panelsPath(viewModelId, modelId), panelId);

                    export const descriptionPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        panelId: DescriptionPanelId
                    ): DBPath => join(panelPath(viewModelId, modelId, panelId), 'description');

                    export const fontSizePath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        panelId: DescriptionPanelId
                    ): DBPath => join(panelPath(viewModelId, modelId, panelId), 'fontSize');

                    export const sizePath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        panelId: DescriptionPanelId
                    ): DBPath => join(panelPath(viewModelId, modelId, panelId), 'size');

                    export const positionsPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                        join(modelContentPath(viewModelId, modelId), 'description-panel-positions');

                    export const positionPath = (
                        viewModelId: ViewModelId,
                        modelId: ModelId,
                        panelId: DescriptionPanelId
                    ): DBPath => join(positionsPath(viewModelId, modelId), panelId);
                }
            }

            export namespace Asset {
                export const assetsPath = (viewModelId: ViewModelId) =>
                    join(viewModelContentPath(viewModelId), 'assets');

                export const assetPath = (viewModelId: ViewModelId, assetId: ViewModelAssetId) =>
                    join(assetsPath(viewModelId), assetId);
            }

            export namespace ViewModelViewer {
                const viewModelViewerContentPath = (viewModelId: ViewModelId): DBPath =>
                    join(ViewModelNamespace, 'viewer-contents', viewModelId);

                export const viewersPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelViewerContentPath(viewModelId), 'viewers');

                export const viewerPath = (viewModelId: ViewModelId, clientId: ClientId): DBPath =>
                    join(viewersPath(viewModelId), clientId);

                export const editingUsersPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelViewerContentPath(viewModelId), 'editing-user');

                export const editingUserPath = (viewModelId: ViewModelId, key: Key): DBPath =>
                    join(editingUsersPath(viewModelId), key.toUrlSafeString());

                export const clientMouseCursorsPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelViewerContentPath(viewModelId), 'client-mouse-cursors');

                export const clientMouseCursorPath = (viewModelId: ViewModelId, clientId: ClientId): DBPath =>
                    join(clientMouseCursorsPath(viewModelId), clientId);

                const clientSelectedItemsPath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelViewerContentPath(viewModelId), 'client-selected-items');

                export const clientSelectedItemPath = (viewModelId: ViewModelId, modelId: ModelId): DBPath =>
                    join(clientSelectedItemsPath(viewModelId), modelId);

                export const huddlePath = (viewModelId: ViewModelId): DBPath =>
                    join(viewModelViewerContentPath(viewModelId), 'huddle');
            }

            export const userOperationModePath = (userId: UserId): DBPath =>
                join(ViewModelNamespace, 'user-operation-modes', userId);
        }

        export namespace AccessControl {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const AccessControlPath = join(AppPath, 'access-control');
        }
    }

    // エイリアス定義
    export const AccessControl = App.AccessControl;
    export const Admin = App.Admin;
    export const ApplicationConfig = App.ApplicationConfig;
    export const Notification = App.Notification;
    export const Group = App.Group;
    export const Mail = App.Mail;
    export const User = App.User;
    export const Workspace = App.Workspace;
    export const ViewModel = App.ViewModel;
    export const ViewModelViewer = App.ViewModel.ViewModelViewer;
    export const ConsistencyLink = App.ViewModel.ConsistencyLink;
    export const Model = App.ViewModel.Model;
    export const View = App.ViewModel.View;
    export const Comment = App.ViewModel.Model.Comment;
    export const DescriptionPanel = App.ViewModel.Model.DescriptionPanel;
    export const Node = App.ViewModel.Model.Node;
    export const Link = App.ViewModel.Model.Link;
    export const Zone = App.ViewModel.Model.Zone;
}
