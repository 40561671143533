import { useCallback } from 'react';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { ReadonlyIcon } from './ReadonlyIcon';
import { usePopupRef } from '@framework/hooks';
import { classNames, useIsDebugMode } from '@framework/utils';
import { IconButton } from './IconButton';
import { MenuButton } from './MenuButton';
import { useUndoRedoHandlers } from './useUndoRedoHandlers';
import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { useModalHandlers } from './useModalHandlers';
import { ViewModelSettingModal, ViewModelDeleteConfirmModal } from '@view-model/ui/components/ViewModel';
import { SidebarOpenButton, useSidebarContext } from '@user/Sidebar';
import { useCurrentUserId, useUserType } from '@framework/auth';
import { faCopy, faObjectGroup, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { faCog, faEllipsisH, faLink, faReply, faStar, faLock, faClock } from '@fortawesome/free-solid-svg-icons';
import { useBookmark } from '../../hooks';
import { WorkspaceEntity } from '@workspace/domain/workspace';
import { ViewModelClipboardOperator } from '@view-model/application/clipboard';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';

type Props = {
    workspace: WorkspaceEntity;
    viewModel: ViewModelEntity;
    viewModelPermissions: { isEditable: boolean; isContentEditable: boolean; isDeletable: boolean };
    onGenerateLink(): void;
    onOpenTimer(): void;
};

export const HeaderMenuLeft: React.FC<Props> = ({
    workspace,
    viewModel,
    viewModelPermissions,
    onGenerateLink,
    onOpenTimer,
}: Props) => {
    const currentUserId = useCurrentUserId();
    const userType = useUserType();
    const { isEditable, isContentEditable, isDeletable } = viewModelPermissions;
    const readonly = !isContentEditable;

    const isDebugMode = useIsDebugMode('timer');

    // ビューモデルのURLをコピー
    const handleCopyLink = useCallback(() => {
        copy(`${location.href}`, { format: 'text/plain' });
        toast('ビューモデルの共有リンクをコピーしました');
        return;
    }, []);

    // ビューモデルをコピー
    const handleCopyViewModel = useCallback(() => {
        ViewModelClipboardOperator.copy(viewModel.workspaceId, viewModel.id).then((success: boolean) => {
            if (success) {
                toast.success('コピーしました | Copied');
            } else {
                toast.error('コピーに失敗しました | Copy failed');
            }
        });
    }, [viewModel.id, viewModel.workspaceId]);

    // 元に戻す、やり直す
    const { handleRedo, handleUndo } = useUndoRedoHandlers();

    // [...] ボタンによるポップアップメニューの開閉状態
    const { isOpen, popupRef, handleClose, handleOpen } = usePopupRef<HTMLDivElement>(false);

    // 現在の表示位置を共有
    const handleGenerateLink = useCallback(() => {
        onGenerateLink();
        handleClose();
    }, [handleClose, onGenerateLink]);

    // ビューモデルの設定
    const {
        modalOpen: settingModalOpen,
        handleModalOpen: settingModalOpenHandler,
        handleModalClose: handleSettingModalClose,
    } = useModalHandlers();

    // ビューモデルの削除
    const {
        modalOpen: deleteModalOpen,
        handleModalOpen: deleteModalOpenHandler,
        handleModalClose: handleDeleteModalClose,
    } = useModalHandlers();

    const handleSettingModalOpen = useCallback(() => {
        settingModalOpenHandler();
        handleClose();
    }, [settingModalOpenHandler, handleClose]);

    const handleDeleteModalOpen = useCallback(() => {
        deleteModalOpenHandler();
        handleClose();
    }, [deleteModalOpenHandler, handleClose]);

    const handleOpenTimer = useCallback(() => {
        onOpenTimer();
        handleClose();
    }, [onOpenTimer, handleClose]);

    const { isSidebarOpenButtonVisible, toggleSidebar } = useSidebarContext();

    const { isBookmarked, toggleBookmark } = useBookmark(viewModel.id);

    // メニューの表示可能な横幅が狭い時に子要素のテキストを省略表示するためには、
    // flexコンテナに min-width を設定し、子要素には `truncate` を設定する必要がある。
    //
    // 参照:
    //   - https://css-tricks.com/flexbox-truncated-text/
    //       flexboxの横幅が狭くなったときに、子要素のテキストを省略表示するためのCSS解説
    //   - https://v1.tailwindcss.com/docs/word-break
    //       tailwind の truncate は、overflow,text-overflow, white-space をまとめて指定している。
    //   - https://v1.tailwindcss.com/docs/min-width
    //       tailwind の標準では min-w-xs が未定義のため、カスタム定義を追加している。
    return (
        <div className="flex gap-2">
            {isSidebarOpenButtonVisible && (
                <div className="pointer-events-auto flex w-12 items-center justify-center rounded-lg bg-white shadow-md hover:bg-gray-200">
                    <SidebarOpenButton onClick={toggleSidebar} />
                </div>
            )}
            <div className="pointer-events-auto flex min-w-xs rounded-lg bg-white p-1 shadow-md">
                {/* ビューモデル名 */}
                <DarkTooltip tooltip={viewModel.name.value} placement="bottom" className="truncate p-2 font-bold">
                    {viewModel.isLocked && <FontAwesomeIcon icon={faLock} className="mr-2" />}
                    {viewModel.name.value}
                </DarkTooltip>

                {/* 閲覧権限で参照中 */}
                {readonly && <ReadonlyIcon />}

                {/* セパレータ */}
                <div className="mx-1 my-2 border-l border-gray-200" />

                {!readonly && (
                    <>
                        {/* Undo Redoボタン */}
                        <IconButton icon={faReply} tooltip="取り消す | Undo" placement="bottom" onClick={handleUndo} />
                        <IconButton
                            icon={faReply}
                            tooltip="やり直す | Redo"
                            placement="bottom"
                            onClick={handleRedo}
                            flip="horizontal"
                        />

                        {/* セパレータ */}
                        <div className="mx-1 my-2 border-l border-gray-200" />
                    </>
                )}

                {/* コピーボタン */}
                <IconButton
                    icon={faLink}
                    tooltip="URLをコピー | Copy link"
                    placement="bottom"
                    onClick={handleCopyLink}
                />
                <IconButton
                    icon={faCopy}
                    tooltip="ビューモデルをコピー | Copy view model"
                    placement="bottom"
                    onClick={handleCopyViewModel}
                />

                {/* ブックマークボタン */}
                {/* ブックマークボタンは、レギュラーユーザーでかつワークスペースメンバーである時に表示 */}
                {currentUserId && userType === 'regular' && workspace.getMemberRoleOf(currentUserId) && (
                    <DarkTooltip
                        tooltip={isBookmarked ? 'ブックマークを削除 | Delete Bookmark' : 'ブックマーク | Bookmark'}
                        placement="bottom"
                    >
                        <button
                            onClick={toggleBookmark}
                            className={classNames(
                                'h-10 w-10 cursor-pointer rounded-lg text-gray-700 hover:bg-gray-200 focus:outline-none',
                                isBookmarked ? 'text-yellow-400' : 'text-gray-400'
                            )}
                        >
                            <FontAwesomeIcon icon={faStar} />
                        </button>
                    </DarkTooltip>
                )}

                {/* KATA MAP ボタン */}
                {userType === 'regular' && (
                    <DarkTooltip tooltip="KATA MAP" placement="bottom">
                        <a
                            href={UserPagePathBuilder.kataMapPage('menu')}
                            className={classNames(
                                'flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg text-gray-700 hover:bg-gray-200 focus:outline-none'
                            )}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faObjectGroup} />
                        </a>
                    </DarkTooltip>
                )}

                {/* さらに見るボタン */}
                <div className="relative">
                    <button
                        className="size-10 cursor-pointer rounded-lg hover:bg-gray-200 focus:outline-none"
                        onClick={handleOpen}
                        aria-label="さらに見る | More"
                    >
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                    <div
                        className={classNames(
                            'absolute right-0 z-10 w-64 rounded-lg bg-white py-2 shadow-lg',
                            isOpen ? 'block' : 'hidden'
                        )}
                        ref={popupRef}
                    >
                        {isEditable && (
                            <>
                                <MenuButton onClick={handleSettingModalOpen} icon={faCog} title="設定 | Settings" />
                                <ViewModelSettingModal
                                    isOpen={settingModalOpen}
                                    onClose={handleSettingModalClose}
                                    workspace={workspace}
                                    viewModel={viewModel}
                                />
                            </>
                        )}
                        {isDebugMode && (
                            <MenuButton onClick={handleOpenTimer} icon={faClock} title="タイマー | Timer" />
                        )}
                        <MenuButton onClick={handleGenerateLink} icon={faLink} title="位置を共有 | Share location" />
                        {isDeletable && (
                            <>
                                <MenuButton
                                    className="text-red-700"
                                    icon={faTrashAlt}
                                    title="削除 | Delete"
                                    onClick={handleDeleteModalOpen}
                                />
                                <ViewModelDeleteConfirmModal
                                    viewModel={viewModel}
                                    isOpen={deleteModalOpen}
                                    onClose={handleDeleteModalClose}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* 匿名ユーザの場合だけ、トライアル登録ページへの導線を表示する */}
            {userType === 'anonymous' && (
                <div className="pointer-events-auto flex items-center rounded-lg bg-white px-4 py-1 shadow-md">
                    <a
                        href="https://levii.co.jp/services/balus/?viewmodel"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-orange-500 hover:underline"
                    >
                        Balusの無料トライアルはこちら
                    </a>
                </div>
            )}
        </div>
    );
};
