import { TimeDisplay } from './TimeDisplay';
import { classNames } from '@framework/utils';

type Props = {
    totalSeconds: number;
    isExpired: boolean;
    isEditable: boolean;
    onMinutesBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onSecondsBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    isEditingMinutes?: boolean;
    isEditingSeconds?: boolean;
    onMinutesClick?: () => void;
    onSecondsClick?: () => void;
};

const formatTimeValue = (value: number): { formattedMinutes: string; formattedSeconds: string } => {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return {
        formattedMinutes: minutes.toString().padStart(2, '0'),
        formattedSeconds: seconds.toString().padStart(2, '0'),
    };
};

export const TimeDisplayContainer: React.FC<Props> = ({
    totalSeconds,
    onMinutesBlur = () => void 0,
    onSecondsBlur = () => void 0,
    isExpired,
    isEditable,
    isEditingMinutes = false,
    isEditingSeconds = false,
    onMinutesClick = () => void 0,
    onSecondsClick = () => void 0,
}: Props) => {
    const { formattedMinutes, formattedSeconds } = formatTimeValue(totalSeconds);

    return (
        <>
            <style>
                {`@keyframes blinkEffect {
                    0% {
                        opacity: 1;
                    }
                    50%  {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }`}
            </style>
            <div
                className={classNames('mx-3 flex text-3xl font-medium', isExpired && 'text-orange-500')}
                style={{
                    ...(isExpired
                        ? {
                              animation: 'blinkEffect 1s 5',
                          }
                        : {}),
                }}
            >
                <TimeDisplay
                    value={formattedMinutes}
                    onBlur={onMinutesBlur}
                    isEditable={isEditable}
                    isEditing={isEditingMinutes}
                    onClick={onMinutesClick}
                />
                <span className={classNames('inline-block w-[1ch] text-center')}>:</span>
                <TimeDisplay
                    value={formattedSeconds}
                    onBlur={onSecondsBlur}
                    isEditable={isEditable}
                    isEditing={isEditingSeconds}
                    onClick={onSecondsClick}
                />
            </div>
        </>
    );
};
