import { useEffect, useRef } from 'react';
import { select, pointer } from 'd3-selection';
import { useD3DblClickCallback } from '@view-model/models/common/hooks';
import { contentTransformAtom } from '@user/pages/ViewModelPage/contentTransformAtom';
import { useAtom } from 'jotai/react';

type Props = {
    children: React.ReactNode;
    onMousePointerDetected: (x: number, y: number) => void;
};

export const MousePointerDetector: React.FC<Props> = (props: Props) => {
    const { children, onMousePointerDetected } = props;
    const [contentTransform] = useAtom(contentTransformAtom);

    const pointerDetectorRef = useRef<SVGGElement>(null);

    useEffect(() => {
        const target = pointerDetectorRef.current;
        select(target).on('mousemove', (event) => {
            const [x, y] = pointer(event);
            onMousePointerDetected(x, y);
        });

        return () => {
            select(target).on('mousemove', null);
        };
    }, [pointerDetectorRef, onMousePointerDetected]);

    useD3DblClickCallback(pointerDetectorRef, () => void 0, true);

    return (
        <g
            ref={pointerDetectorRef}
            transform={`translate(${contentTransform.x}, ${contentTransform.y}) scale(${contentTransform.k})`}
        >
            {children}
        </g>
    );
};
