import { DataSnapshot, DBPath, RefBuilder, Reference } from '@framework/repository';
import { useMemo, useRef, useState, useEffect } from 'react';

type UseValue<O> = [
    values: O | null,
    {
        ref: Reference;
        loading: boolean;
    },
];

type State<T> = {
    values: null | T;
    loading: boolean;
};

const onErrorDefault = (error: Error) => {
    console.error('Error on useSnapshot():', error);
};

type Options<O> = {
    path: DBPath;
    load: (params: { snapshot: DataSnapshot; getChildValues: () => Record<string, unknown> }) => O;
    stateless?: boolean;
    skip?: boolean;
    onError?: (error: Error) => void;
};

/**
 * 指定パス配下の複数のレコードを取得する
 *
 * @param options.load snapshotから取得したデータを加工して返す関数
 * @param options.skip trueにした場合、データの取得をスキップする
 * @param options.stateless trueに設定するとローカルの状態を持たないようになる。返り値の値は常にnullになる
 * @param options.onError エラー時の処理
 */
export const useSnapshot = <O>(options: Options<O>): UseValue<O> => {
    const { path, skip = false } = options;

    const optionsRef = useRef<Options<O>>(options);
    optionsRef.current = options;

    const ref = useMemo(() => RefBuilder.ref(path), [path]);

    const [state, setState] = useState<State<O>>({
        values: null,
        loading: true,
    });

    useEffect(() => {
        if (skip) {
            return;
        }

        const onValue = (snapshot: DataSnapshot) => {
            const getChildValues = () => {
                const values = {} as Record<string, unknown>;
                snapshot.forEach((child) => {
                    values[child.key] = child.val();
                });
                return values;
            };

            if (optionsRef.current.stateless) {
                optionsRef.current.load({
                    snapshot,
                    getChildValues,
                });
            } else {
                setState((prev) => ({
                    ...prev,
                    values: optionsRef.current.load({
                        snapshot,
                        getChildValues,
                    }),
                    loading: false,
                }));
            }
        };

        ref.on('value', onValue, (error) => {
            const onError = optionsRef.current.onError || onErrorDefault;
            onError(error);
        });

        return () => {
            ref.off('value', onValue);
        };
    }, [ref, skip]);

    return [
        state.values,
        {
            ref,
            loading: state.loading,
        },
    ];
};
