import { useState, createContext, useContext } from 'react';
import { DescriptionPanelId } from '@schema-common/base';

type EditingDescriptionPanelContextType = {
    editingPanelId: DescriptionPanelId | null;
    setEditingPanelId(editingPanelId: DescriptionPanelId | null): void;
};

const EditingDescriptionPanelContext = createContext<EditingDescriptionPanelContextType>({
    editingPanelId: null,
    setEditingPanelId: () => {},
});

type Props = {
    children: React.ReactNode;
};

export const EditingDescriptionPanelContextProvider: React.FC<Props> = ({ children }: Props) => {
    const [editingPanelId, setEditingPanelId] = useState<DescriptionPanelId | null>(null);

    return (
        <EditingDescriptionPanelContext.Provider value={{ editingPanelId, setEditingPanelId }}>
            {children}
        </EditingDescriptionPanelContext.Provider>
    );
};

export const useEditingDescriptionPanel = (): EditingDescriptionPanelContextType => {
    const { editingPanelId, setEditingPanelId } = useContext(EditingDescriptionPanelContext);
    return { editingPanelId, setEditingPanelId };
};
