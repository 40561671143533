import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Loading } from '@framework/ui/atoms';
import { useDetectGroupId, useGroupEntity } from '@group/hooks';
import { SidebarTree, useSidebarTree, SidebarTreeContext, SidebarBookmarks } from './SidebarTree';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { faBuilding, faObjectGroup } from '@fortawesome/free-regular-svg-icons';
import { SidebarSearchBox, useSidebarSearch } from './SidebarSearch';
import { SidebarTabContainer, SidebarTabTarget } from './SidebarTabContainer';
import { Timestamp } from '@framework/Timestamp';
import { CookieUtils } from '@framework/CookieUtils';

type Props = {
    handleSidebarOpen: () => void;
};

const SidebarTargetCookieName = 'balus_sidebar';

const setSidebarTargetToCookie = (target: SidebarTabTarget) => {
    const now = Timestamp.now();
    const expires = now.addDays(365).toDate();
    CookieUtils.set(SidebarTargetCookieName, JSON.stringify(target), { expires });
};

const getSidebarTargetFromCookie = (): SidebarTabTarget => {
    const cookieValue = CookieUtils.get(SidebarTargetCookieName);

    if (!cookieValue) return 'Shared';

    return JSON.parse(cookieValue) || 'Shared';
};

export const SidebarContainer: React.FC<Props> = ({ handleSidebarOpen }: Props) => {
    const targetGroupId = useDetectGroupId();
    const { firstLoaded, ...sidebarTreeContextValues } = useSidebarTree(targetGroupId);
    const { loading: groupLoading, group } = useGroupEntity(targetGroupId);
    const { searchKeyword, isSearchMode, handleSearch } = useSidebarSearch(
        sidebarTreeContextValues.all,
        sidebarTreeContextValues.dispatch
    );

    const [sidebarTabTarget, setSidebarTabTarget] = useState<SidebarTabTarget>(getSidebarTargetFromCookie());

    const handleShowShared = useCallback(() => {
        setSidebarTabTarget('Shared');
        setSidebarTargetToCookie('Shared');
    }, []);
    const handleShowPersonal = useCallback(() => {
        setSidebarTabTarget('Personal');
        setSidebarTargetToCookie('Personal');
    }, []);
    const handleShowBookmarks = useCallback(() => {
        setSidebarTabTarget('Bookmark');
        setSidebarTargetToCookie('Bookmark');
    }, []);

    if (groupLoading) {
        return (
            <div>
                <Loading />
            </div>
        );
    }

    if (group === null) {
        return <div className="m-4 flex justify-center">グループが見つかりません</div>;
    }

    return (
        <>
            <div className="pb-1">
                <div className="ml-4 pb-2 pt-6">
                    <div className="mt-1 flex justify-between">
                        <Link
                            to={UserPagePathBuilder.groupPage(group.id)}
                            className="flex max-w-lg items-center truncate text-lg font-bold text-black hover:text-blue-900"
                        >
                            <span className="mr-1">
                                {group.imageUrl ? (
                                    <span className="inline-block size-8 overflow-hidden rounded-full align-middle">
                                        <img
                                            className="size-full object-cover"
                                            src={group.imageUrl}
                                            alt={group.name.toString()}
                                        />
                                    </span>
                                ) : (
                                    <FontAwesomeIcon icon={faBuilding} className="text-brand" />
                                )}
                            </span>
                            {group.name.toString()}
                        </Link>
                        <button
                            className="mx-4 focus:outline-none"
                            onClick={handleSidebarOpen}
                            aria-label="サイドバーを閉じる"
                        >
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </button>
                    </div>
                    <div className="mt-4 rounded-sm py-1 hover:bg-gray-300">
                        <a
                            href={UserPagePathBuilder.kataMapPage('sidebar')}
                            target="_blank"
                            rel="noreferrer"
                            className="flex items-center justify-start"
                        >
                            <FontAwesomeIcon icon={faObjectGroup} className="mr-1 text-brand" />
                            <span>KATA MAP</span>
                        </a>
                    </div>
                    <div className="my-2 mr-4">
                        <SidebarSearchBox onSearch={handleSearch} />
                    </div>
                </div>
                <div>
                    <SidebarTabContainer
                        isSearchMode={isSearchMode}
                        target={sidebarTabTarget}
                        handleShowShared={handleShowShared}
                        handleShowPersonal={handleShowPersonal}
                        handleShowBookmarks={handleShowBookmarks}
                    />
                </div>
            </div>

            <div className="ml-4">
                {/* フォルダツリー */}
                {/* 165pxはサイドバーのスクロールしない部分の高さ。本当はrefを使って高さを取得したいが、レンダリング時点でref.currentがundefinedになるので直接指定している */}
                <SidebarTreeContext.Provider value={{ ...sidebarTreeContextValues, firstLoaded, searchKeyword }}>
                    <div
                        className="overflow-y-auto overflow-x-hidden text-sm"
                        style={{ height: 'calc(100vh - 210px)' }}
                    >
                        {/* 検索結果 > ブックマーク > 通常(ワークスペース) の優先度でサイドバーは表示される */}
                        {/* 検索結果と通常(ワークスペース)はどちらもSidebarTreeを使って表示される */}
                        {!firstLoaded ? (
                            <Loading />
                        ) : isSearchMode ? (
                            <SidebarTree target="SearchResult" />
                        ) : sidebarTabTarget === 'Bookmark' ? (
                            <SidebarBookmarks />
                        ) : (
                            <SidebarTree target={sidebarTabTarget} />
                        )}
                        <div className="m-8" />
                    </div>
                </SidebarTreeContext.Provider>
            </div>
        </>
    );
};
