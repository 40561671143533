import { Timestamp } from '@framework/Timestamp';
import { TimerJSON } from '@schema-app/view-model/timer/{ViewModelId}/TimerJSON';

type TimerState = 'running' | 'paused' | 'completed';

type TimerRangeSeconds = number; // タイマーの最大値は 99分59秒

type RunningTimerAttributes = {
    expireAt: Timestamp;
    currentState: 'running';
    initialDuration: TimerRangeSeconds;
};

type PausedTimerAttributes = {
    expireAt: Timestamp;
    currentState: 'paused';
    initialDuration: TimerRangeSeconds;
    remainingDuration: TimerRangeSeconds;
};

type CompletedTimerAttributes = {
    expireAt: Timestamp;
    currentState: 'completed';
    initialDuration: TimerRangeSeconds;
};

type TimerAttributes = RunningTimerAttributes | PausedTimerAttributes | CompletedTimerAttributes;
export class TimerEntity {
    public readonly expireAt: Timestamp;
    public readonly currentState: TimerState;
    public readonly initialDuration: TimerRangeSeconds;
    public readonly remainingDuration: TimerRangeSeconds | null;

    constructor(attributes: TimerAttributes) {
        this.expireAt = attributes.expireAt;
        this.currentState = attributes.currentState;
        this.initialDuration = attributes.initialDuration;
        this.remainingDuration = attributes.currentState === 'paused' ? attributes.remainingDuration : null;
    }

    static load(dump: TimerJSON): TimerEntity {
        const { expireAt, currentState, initialDuration } = dump;

        if (currentState === 'paused') {
            const attributes = {
                expireAt: new Timestamp(expireAt),
                currentState: currentState,
                initialDuration,
                remainingDuration: dump.remainingDuration!,
            } satisfies PausedTimerAttributes;
            return new TimerEntity(attributes);
        }

        const attributes = {
            expireAt: new Timestamp(expireAt),
            currentState: currentState,
            initialDuration,
        } satisfies RunningTimerAttributes | CompletedTimerAttributes;
        return new TimerEntity(attributes);
    }

    dump(): TimerJSON {
        const { expireAt, currentState, initialDuration, remainingDuration } = this;
        const base = {
            expireAt: expireAt.toUnixTimestamp(),
            initialDuration,
        };

        switch (currentState) {
            case 'paused':
                return {
                    ...base,
                    currentState: 'paused',
                    remainingDuration: remainingDuration!,
                };
            case 'completed':
                return {
                    ...base,
                    currentState: 'completed',
                };
            case 'running':
                return {
                    ...base,
                    currentState: 'running',
                };
        }
    }

    isRunning(): this is RunningTimerAttributes {
        return this.currentState === 'running';
    }

    isPaused(): this is PausedTimerAttributes {
        return this.currentState === 'paused';
    }

    isCompleted(): this is CompletedTimerAttributes {
        return this.currentState === 'completed';
    }

    setCompleted(): TimerEntity {
        const { expireAt, initialDuration } = this;
        return new TimerEntity({
            expireAt,
            initialDuration,
            currentState: 'completed',
        });
    }

    setPaused(remainingDuration: TimerRangeSeconds): TimerEntity {
        const { expireAt, initialDuration } = this;
        return new TimerEntity({
            expireAt,
            initialDuration,
            currentState: 'paused',
            remainingDuration,
        });
    }

    setRunning(expireAt: Timestamp): TimerEntity {
        const { initialDuration } = this;
        return new TimerEntity({
            expireAt,
            initialDuration,
            currentState: 'running',
        });
    }
}
