import { useCallback, useEffect, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Editor } from '@tiptap/core';
import { Button } from '@framework/ui/atoms/Button';
import { Input } from '@framework/ui/atoms/Input';

type Props = {
    isOpen: boolean;
    editor: Editor | null;
    onClose(): void;
};

export const DescriptionEditorSetImageUrlModal: React.FC<Props> = ({ isOpen, editor, onClose }: Props) => {
    const [imageUrl, setImageUrl] = useState<string>('');
    const [alt, setAlt] = useState<string>('');

    useEffect(() => {
        if (editor && isOpen) {
            const previousImageUrl = editor.getAttributes('image').src;
            const previousAlt = editor.getAttributes('image').alt;
            setImageUrl(previousImageUrl);
            setAlt(previousAlt);
        }
    }, [editor, isOpen]);

    const handleSubmit = useCallback(() => {
        if (!editor) return;
        editor.chain().focus().setImage({ src: imageUrl, alt }).run();
        setImageUrl('');
        setAlt('');
        onClose();
    }, [editor, onClose, imageUrl, alt]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'画像を追加 | Add Image'}
            submitButton={
                <Button color="primary" onClick={handleSubmit}>
                    保存 | Done
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる | Cancel
                </Button>
            }
        >
            <div className="p-4">
                <div className="pb-2 font-bold">画像URL | Image URL</div>
                <Input
                    type="text"
                    placeholder="挿入する画像のURL"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    autoFocus={true}
                />
            </div>
            <div className="p-4">
                <div className="pb-2 font-bold">画像タイトル | Image Title</div>
                <Input type="text" placeholder="画像のタイトル" value={alt} onChange={(e) => setAlt(e.target.value)} />
            </div>
        </GeneralModal>
    );
};
