import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLink,
    faQuoteRight,
    faListOl,
    faListUl,
    faCode,
    faBold,
    faRotate,
    faImage,
    faStrikethrough,
} from '@fortawesome/free-solid-svg-icons';
import { Editor } from '@tiptap/react';
import { Level } from '@tiptap/extension-heading';
import { DescriptionPanelFontSize } from '../../domain';
import { FontSize } from '@model-framework/text';

type Props = {
    editor: Editor | null;
    fontSize: DescriptionPanelFontSize;
    isMarkdownMode: boolean;
    onFontSizeChange(fontSize: DescriptionPanelFontSize): void;
    onModeChange(): void;
    onURLModalOpen(): void;
    onImageURLModalOpen(): void;
    onChangeHeight(height: number): void;
};

export const TOOLBAR_HEIGHT = 72;

export const EditorToolbar = ({
    editor,
    fontSize,
    isMarkdownMode,
    onFontSizeChange,
    onModeChange,
    onURLModalOpen,
    onImageURLModalOpen,
    onChangeHeight,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const changeHeading = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const value = parseInt(event.target.value);
            if (value == 0) {
                editor?.chain().focus().setParagraph().run();
            } else {
                const level = value as Level;
                editor?.chain().focus().setHeading({ level }).run();
            }
        },
        [editor]
    );

    const handleLink = useCallback(() => {
        onURLModalOpen();
    }, [onURLModalOpen]);

    const handleImage = useCallback(() => {
        onImageURLModalOpen();
    }, [onImageURLModalOpen]);

    const handleCodeBlock = useCallback(() => {
        editor?.chain().focus().toggleCodeBlock().run();
    }, [editor]);

    useEffect(() => {
        if (ref.current && ref.current.clientHeight !== 0) {
            onChangeHeight(ref.current.clientHeight);
        }
    }, [fontSize, onChangeHeight]);

    if (!editor) {
        return null;
    }

    return (
        <div ref={ref} className="flex flex-wrap items-center justify-between p-2 text-2xl">
            <div className="flex flex-wrap items-center">
                <div className="flex h-10 border border-gray-500">
                    <button
                        type="button"
                        onClick={() => onFontSizeChange(FontSize.S)}
                        className={
                            DescriptionPanelFontSize.load(fontSize) === FontSize.S
                                ? 'flex size-10 items-center justify-center bg-brand text-lg font-bold text-white'
                                : 'flex size-10 items-center justify-center text-lg font-bold text-gray-500'
                        }
                    >
                        A
                    </button>
                    <button
                        type="button"
                        onClick={() => onFontSizeChange(FontSize.M)}
                        className={
                            DescriptionPanelFontSize.load(fontSize) === FontSize.M
                                ? 'flex size-10 items-center justify-center bg-brand text-2xl font-bold text-white'
                                : 'flex size-10 items-center justify-center text-2xl font-bold text-gray-500'
                        }
                    >
                        A
                    </button>
                    <button
                        type="button"
                        onClick={() => onFontSizeChange(FontSize.L)}
                        className={
                            DescriptionPanelFontSize.load(fontSize) === FontSize.L
                                ? 'flex size-10 items-center justify-center bg-brand text-3xl font-bold text-white'
                                : 'flex size-10 items-center justify-center text-3xl font-bold text-gray-500'
                        }
                    >
                        A
                    </button>
                </div>
                {!isMarkdownMode && (
                    <>
                        <div className="flex items-center pl-2">
                            <select
                                name="heading"
                                className="form-select w-64 p-2"
                                defaultValue={0}
                                onChange={changeHeading}
                            >
                                <option value={0}>標準テキスト</option>
                                <option value={1}>見出し1</option>
                                <option value={2}>見出し2</option>
                                <option value={3}>見出し3</option>
                                <option value={4}>見出し4</option>
                            </select>
                        </div>
                        <div className="flex items-center pl-2">
                            <button
                                type="button"
                                onClick={() => editor.chain().focus().toggleBold().run()}
                                className={!editor.isActive('bold') ? 'pr-2 opacity-20' : 'pr-2'}
                            >
                                <FontAwesomeIcon icon={faBold} />
                            </button>
                            <button
                                type="button"
                                onClick={() => editor.chain().focus().toggleStrike().run()}
                                className={!editor.isActive('strike') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faStrikethrough} />
                            </button>
                            <button
                                type="button"
                                onClick={handleCodeBlock}
                                className={!editor.isActive('codeBlock') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faCode} />
                            </button>
                            <button
                                type="button"
                                onClick={() => editor.chain().focus().toggleBulletList().run()}
                                className={!editor.isActive('bulletList') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faListUl} />
                            </button>
                            <button
                                type="button"
                                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                                className={!editor.isActive('orderedList') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faListOl} />
                            </button>
                            <button
                                type="button"
                                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                                className={!editor.isActive('blockquote') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faQuoteRight} />
                            </button>
                            <button
                                type="button"
                                onClick={handleLink}
                                className={!editor.isActive('link') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faLink} />
                            </button>
                            <button
                                type="button"
                                onClick={handleImage}
                                className={!editor.isActive('image') ? 'px-2 opacity-20' : 'px-2'}
                            >
                                <FontAwesomeIcon icon={faImage} />
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="flex flex-wrap">
                <button
                    onClick={onModeChange}
                    className="rounded bg-gray-500 py-1 pl-3 pr-2 font-bold text-white hover:bg-gray-700 focus:outline-none"
                >
                    <FontAwesomeIcon icon={faRotate} className="mr-1" />
                    {isMarkdownMode ? 'リッチエディタ' : 'Markdownエディタ'}
                </button>
            </div>
        </div>
    );
};
