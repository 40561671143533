import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = {
    className?: string;
    source: string;
};

export const Markdown: React.FC<Props> = ({ className, source }: Props) => {
    return (
        <ReactMarkdown
            className={className}
            remarkPlugins={[remarkGfm]}
            components={{
                a: ({ node, ...props }) => <a {...props} target="_blank" rel="noreferrer" />,
            }}
        >
            {source}
        </ReactMarkdown>
    );
};
