import { useEffect, useRef } from 'react';

type Callback = (counter: number) => number;
type Cleanup = () => void;

/**
 * 定期的に処理を実行するsetIntervalのラッパー
 *
 * @param callback 定期的に呼ばれる関数。引数で渡されるカウンターのカウントアップ等は自前で行い、返却する必要がある。
 * @param cleanup タイマー停止時に実行する関数
 * @param delay callback関数を呼び出す間隔（ミリ秒）。nullを設定した場合は何もせずに終了する。
 * @param initialCounterValue カウンターの初期値
 */
export const useInterval = <S extends Callback, T extends Cleanup>({
    callback,
    cleanup,
    delay,
    initialCounterValue,
}: {
    callback: S;
    cleanup: T;
    delay: number | null;
    initialCounterValue: number;
}): void => {
    //
    const savedCallback = useRef<S | null>(null);
    const savedCleanup = useRef<T | null>(null);

    // callback, cleanup関数をRefに設定している理由については以下の記事を参照してください
    //
    // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    useEffect(() => {
        savedCallback.current = callback;
        savedCleanup.current = cleanup;
    });

    useEffect(() => {
        if (delay === null) {
            // 指定されていない場合は何も実行しない
            return;
        }

        let counter = initialCounterValue;
        if (savedCallback.current) {
            // １回目の実行
            counter = savedCallback.current(counter);
        }

        const timerId = setInterval(() => {
            if (savedCallback.current !== null) {
                // ２回目以降の実行
                counter = savedCallback.current(counter);
            }
        }, delay);

        return () => {
            clearInterval(timerId);
            // 後処理
            savedCleanup.current?.();
        };
    }, [delay, initialCounterValue]);
};
