import { useEffect, useRef } from 'react';

type InputSize = 'sm' | 'md';

type Props = Omit<React.JSX.IntrinsicElements['input'], 'size'> & {
    autoSelect?: boolean;
    size?: InputSize;
};

const sizeDefinitions: { [key in InputSize]: string } = {
    sm: 'py-1.5 px-2 text-sm',
    md: 'py-2 px-3',
};

export const Input: React.FC<Props> = ({
    onChange,
    onBlur,
    onKeyDown,
    value,
    type,
    placeholder,
    className,
    disabled,
    autoFocus,
    autoSelect,
    size = 'md',
    ...rest
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current && autoSelect) {
            inputRef.current.select();
        }
    }, [autoSelect]);

    return (
        <input
            {...rest}
            ref={inputRef}
            value={value}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            className={[
                sizeDefinitions[size],
                'appearance-none',
                'border',
                'rounded',
                'w-full',
                'text-gray-700',
                'leading-tight',
                'focus:outline-none',
                'focus:ring',
                className,
            ].join(' ')}
            disabled={disabled}
            autoFocus={autoFocus}
        />
    );
};
