import { DarkTooltip } from '@framework/ui/atoms/Tooltip';
import { classNames } from '@framework/utils';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    tooltip: string;
    icon?: IconDefinition;
    caption?: string;
    className?: string;
    onClick(): void;
};

export const Button: React.FC<Props> = ({ tooltip, icon, caption, className, onClick }: Props) => {
    const width = caption ? 'px-4' : 'w-10';

    return (
        <div className="pointer-events-auto">
            <DarkTooltip tooltip={tooltip} placement="top">
                <button
                    onClick={onClick}
                    className={classNames(
                        `flex h-10 ${width} cursor-pointer items-center justify-center bg-white text-gray-800 shadow-md hover:bg-gray-200 focus:outline-none`,
                        className
                    )}
                    aria-label={tooltip}
                >
                    {icon && <FontAwesomeIcon icon={icon} />}
                    {caption ? caption : ''}
                </button>
            </DarkTooltip>
        </div>
    );
};
