import { RefObject, useEffect } from 'react';
import { select } from 'd3-selection';

export const useD3RightClickCallback = <T extends HTMLElement | SVGElement>(
    ref: RefObject<T>,
    onClick: (event: MouseEvent) => void,
    preventDefault = false,
    stopPropagation = false
): void => {
    useEffect(() => {
        const dom = ref.current;
        select(dom).on('contextmenu', (event: MouseEvent) => {
            if (preventDefault) {
                // 右クリックでコンテキストメニューを出さないようにする
                event.preventDefault();
            }
            if (stopPropagation) {
                event.stopPropagation();
            }
            onClick(event);
        });

        return () => {
            select(dom).on('contextmenu', null);
        };
    }, [ref, onClick, stopPropagation, preventDefault]);
};
