import { useCallback, useEffect, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Editor } from '@tiptap/core';
import { Button } from '@framework/ui/atoms/Button';
import { Input } from '@framework/ui/atoms/Input';

type Props = {
    isOpen: boolean;
    editor: Editor | null;
    onClose(): void;
};

export const DescriptionEditorSetUrlModal: React.FC<Props> = ({ isOpen, editor, onClose }: Props) => {
    const [url, setUrl] = useState<string>('');

    useEffect(() => {
        if (editor && isOpen) {
            const previousUrl = editor.getAttributes('link').href;
            setUrl(previousUrl);
        }
    }, [editor, isOpen]);

    const handleSubmit = useCallback(() => {
        if (!editor) return;
        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
        } else {
            editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
            setUrl('');
        }
        onClose();
    }, [editor, onClose, url]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={onClose}
            title={'リンクを追加 | Add Link'}
            submitButton={
                <Button color="primary" onClick={handleSubmit}>
                    保存 | Done
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる | Cancel
                </Button>
            }
        >
            <div className="p-4">
                <div className="pb-2 font-bold">リンクURL | Link URL</div>
                <Input
                    type="text"
                    placeholder="挿入するリンクのURL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    autoFocus={true}
                />
            </div>
        </GeneralModal>
    );
};
