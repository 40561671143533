import { useMemo } from 'react';
import { CookieUtils } from '@framework/CookieUtils';

const CookieName = 'balus_request_path';

type Result = {
    store: () => void;
    fetch: () => string;
};

/**
 * ログイン処理時のリクエストパスを保存・取得する間数を返す。
 * リクエストパスの値はCookieに保持する。
 *
 * 従来は、ログイン後にリダイレクトするパスをクエリパラメータ (dst_url など) として取り回していたが、
 * Balusアプリ内のリダイレクト先の情報を、Jijiまで取り回す必要は無いため、フロントエンドで完結できる Cookie 保持に変更した。
 *
 * @returns
 */
export const useStoreRequestPath = (): Result => {
    return useMemo(() => {
        const store = () => {
            const params = new URLSearchParams(window.location.search);
            params.delete('logout'); // ログアウト後に付与されるクエリパラメータは取り除く

            // クエリパラメータの 'requestPath' が設定されていればその値を、
            // 指定されていなければ、現在表示中のURLのパスを、 Cookie に保存して、ログイン後の遷移先とする。
            const currentPath = `${window.location.pathname}?${params.toString()}`;
            const value = params.get('requestPath') || currentPath;
            CookieUtils.set(CookieName, value, { path: '/', expires: 10 * 60 });
        };

        const fetch = () => {
            const cookies = document.cookie.split('; ');
            const cookieValue = cookies.find((cookie) => cookie.startsWith(`${CookieName}=`));
            if (!cookieValue) return '/';

            const value = cookieValue.replace(`${CookieName}=`, '');

            return value;
        };

        return { store, fetch };
    }, []);
};
