import { contentTransformAtom } from '@user/pages/ViewModelPage/contentTransformAtom';
import { useAtom } from 'jotai/react';
import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { ScaleExtent } from './domain/ScaleExtent';
import { ZoomTransform } from 'd3';
import { useUserOperationModes } from '@user/pages/ViewModelPage/UserOperationModesProvider';
import { useD3RightClickCallback } from '@view-model/models/common/hooks/useD3RightClickCallback';
import { ViewBox } from '@view-model/domain/view-model';
import { Point } from '@view-model/models/common/basic';
import { useZoomPanHandler } from './hook/useZoomPanHandler';

type Props = {
    children: React.ReactNode;
    scaleExtent: ScaleExtent;
    viewBox: ViewBox;
    transition: string;
};

// 親コンポーネントから呼ばれる関数
export type SVGZoomPanHandlerRef = {
    resetZoom: (centerPosition: Point, scale: number) => void;
    zoom: (scale: number) => void;
};

const SVGZoomPanHandlerFn = (props: Props, forwardedRef: ForwardedRef<SVGZoomPanHandlerRef>) => {
    const { children, scaleExtent, viewBox, transition } = props;
    const { userOperationMode } = useUserOperationModes();
    const [, setContentTransform] = useAtom(contentTransformAtom);

    const zoomPanHandlerRef = useRef<SVGGElement>(null);

    // ここではjotaiの変数に新しいtransformを設定するだけ
    // 別コンポーネントからこの値を参照し、svg要素にtransformを設定する
    const onZoom = useCallback(
        (t: ZoomTransform) => {
            setContentTransform(t);
        },
        [setContentTransform]
    );

    // 操作モードが「スライドモード」の時は右クリックメニュー（contextmenu）を無効にする
    useD3RightClickCallback(zoomPanHandlerRef, () => {}, userOperationMode === 'slide');

    // マウス操作のZoom/Panningに関してはこのコンポーネントでハンドリングするが、画面ボタン等によるZoom操作を可能
    // とするため、forwardRefで一部の機能をコンポーネントの外から呼び出せるように公開する
    const { resetZoom, zoom } = useZoomPanHandler({
        zoomPanHandlerRef,
        userOperationMode,
        scaleExtent,
        viewBox,
        onZoom,
    });
    useImperativeHandle(forwardedRef, () => ({
        resetZoom,
        zoom,
    }));

    return (
        <svg
            pointerEvents="all"
            style={{ transition }}
            width={viewBox.width}
            height={viewBox.height}
            viewBox={viewBox.toSVGAttribute()}
        >
            <g ref={zoomPanHandlerRef}>{children}</g>
        </svg>
    );
};

// TODO: React 19になったらforwardRefは不要（非推奨）
export const SVGZoomPanHandler = forwardRef<SVGZoomPanHandlerRef, Props>(SVGZoomPanHandlerFn);
