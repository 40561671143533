import { useCallback, useState } from 'react';
import MoveAnimeGif from '@assets/move-anime.gif';
import SelectAnimeGif from '@assets/select-anime.gif';
import NoAnimePng from '@assets/no-anime.png';
import { useInterval } from '@framework/hooks/useInterval';
import { UserOperationModes } from '@user/pages/ViewModelPage/UserOperationModesProvider';

type Props = {
    title: string;
    explanation: Explanation;
    isSelected: boolean;
    mode: UserOperationModes;
    tempModeSwitcher: (mode: UserOperationModes) => void;
};

export type Explanation = {
    mouse: {
        move: string;
        select: string;
    };
    trackpad: {
        move: string;
        select: string;
    };
};

type ExplanationActive = 'move' | 'select' | '';
type UISet = {
    animeGif: string;
    explanationActive: ExplanationActive;
};

const HIGHLIGHT_STYLE = 'text-blue-500';
const NON_HIGHLIGHT_STYLE = '';

const ROTATION_INTERVAL = 3200;
const ROTATION: UISet[] = [
    {
        animeGif: MoveAnimeGif,
        explanationActive: 'move',
    },
    {
        animeGif: SelectAnimeGif,
        explanationActive: 'select',
    },
];
const PAUSE_UI_SET: UISet = {
    animeGif: NoAnimePng,
    explanationActive: '',
};

export const UserOperationModesPanel: React.FC<Props> = ({
    title,
    explanation,
    isSelected,
    mode,
    tempModeSwitcher,
}: Props) => {
    const handleClick = useCallback(() => {
        tempModeSwitcher(mode);
    }, [mode, tempModeSwitcher]);

    const [currentUISet, setCurrentUISet] = useState<UISet>(PAUSE_UI_SET);

    const rotateUISet = (index: number | null) => {
        setCurrentUISet(index !== null ? ROTATION[index] : PAUSE_UI_SET);
    };

    const getStyle = (explanationActive: ExplanationActive) => {
        return currentUISet.explanationActive === explanationActive ? HIGHLIGHT_STYLE : NON_HIGHLIGHT_STYLE;
    };

    // delayで設定した間隔でUIセット（アニメーション画像、説明文のハイライト）を切り替える
    // （２パターンのセットを交互に繰り返す）
    useInterval({
        callback: (i: number) => {
            rotateUISet(i);
            i++;

            if (i === ROTATION.length) {
                i = 0;
            }
            return i;
        },
        cleanup: () => {
            rotateUISet(null);
        },
        delay: isSelected ? ROTATION_INTERVAL : null,
        initialCounterValue: 0,
    });

    return (
        <div
            className={`flex flex-col rounded-xl ${isSelected ? 'border-blue-500' : ''} m-2 justify-center border-2 p-5 hover:cursor-pointer`}
            onClick={handleClick}
        >
            <h1 className="mb-5 text-center text-lg font-bold">{title}</h1>
            <img src={currentUISet.animeGif} width="200px" height="200px" />
            <div className="pt-3 text-xs">
                <dl>
                    <dt className="font-bold">マウス</dt>
                    <dd className={getStyle('move')}>{explanation.mouse.move}</dd>
                    <dd className={getStyle('select')}>{explanation.mouse.select}</dd>
                </dl>
            </div>
            <div className="pt-3 text-xs">
                <dl>
                    <dt className="font-bold">トラックパッド</dt>
                    <dd className={getStyle('move')}>{explanation.trackpad.move}</dd>
                    <dd className={getStyle('select')}>{explanation.trackpad.select}</dd>
                </dl>
            </div>
        </div>
    );
};
