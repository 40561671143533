import { Timer } from './Timer';
import { ViewModelId } from '@schema-common/base';
import { useTimeControl } from './useTimeControl';
import { useCallback } from 'react';

type Props = {
    showTimer: boolean;
    onOpen(): void;
    onClose(): void;
    viewModelId: ViewModelId;
};

export const TimerContainer: React.FC<Props> = ({ showTimer, onOpen, onClose, viewModelId }: Props) => {
    const {
        totalSeconds,
        isExpired,
        isRunning,
        handleControlTimer,
        handleResetTimer,
        handleIncreaseTime,
        handleDecreaseTime,
        handleUpdateTime,
        handleResetExpiredState,
    } = useTimeControl(viewModelId, onOpen);

    const handleClose = useCallback(() => {
        onClose();
        if (isExpired) {
            handleResetExpiredState();
        }
    }, [onClose, handleResetExpiredState, isExpired]);

    if (!showTimer) return null;

    return (
        <div className="pointer-events-auto absolute right-8 top-20">
            <Timer
                totalSeconds={totalSeconds}
                isRunning={isRunning}
                onControlTimer={handleControlTimer}
                onResetTimer={handleResetTimer}
                onIncreaseTime={handleIncreaseTime}
                onDecreaseTime={handleDecreaseTime}
                onUpdateTime={handleUpdateTime}
                onClose={handleClose}
                isExpired={isExpired}
            />
        </div>
    );
};
