// eslint-disable-next-line no-restricted-imports
import Cookies from 'js-cookie';

export class CookieUtils {
    static set(key: string, value: string, attributes: Cookies.CookieAttributes = {}): string | undefined {
        const isHttps = location.protocol === 'https:';
        const defaultAttributes: Cookies.CookieAttributes = {
            secure: isHttps, // 開発環境以外のhttps以下ではsecure属性を自動的につける
        };

        return Cookies.set(key, value, { ...defaultAttributes, ...attributes });
    }

    static get(key: string): string | undefined {
        return Cookies.get(key);
    }

    static getAll(): Record<string, string> {
        return Cookies.get();
    }
}
