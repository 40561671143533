import { useEffect, useState } from 'react';
import { EulaAgreementFooter } from './EulaAgreementFooter';
import { useLocation } from 'react-router-dom';
import { Timestamp } from '@framework/Timestamp';
import { FirebaseAnalyticsOperation } from '@framework/firebase/analytics';
import { CookieUtils } from '@framework/CookieUtils';

const EULA_VERSION = 'v202108';
const EULA_COOKIE_KEY = `balus-eula-agreement-${EULA_VERSION}`;

/**
 * EULA (End-user License Agreement) に対する同意確認を必要に応じて表示する。
 *
 * @returns
 */
export const EulaFooter: React.FC<unknown> = () => {
    const location = useLocation();
    const [agreed, setAgreed] = useState<boolean>(true);

    useEffect(() => {
        setAgreed(!!CookieUtils.getAll()[EULA_COOKIE_KEY]);
    }, []);

    const handleAgree = () => {
        const now = Timestamp.now();
        const value = now.toISOString();
        const expires = now.addDays(365 * 10).toDate();
        CookieUtils.set(EULA_COOKIE_KEY, value, { expires });
        setAgreed(true);

        FirebaseAnalyticsOperation.logEvent('eula_agreement', {
            page_path: location.pathname,
            search: location.search,
            hash: location.hash,
            eula_version: EULA_VERSION,
        });
    };

    return (
        <>
            {!agreed && (
                <div className="pointer-events-none fixed bottom-0 w-full">
                    <EulaAgreementFooter onAgree={handleAgree} />
                </div>
            )}
        </>
    );
};
