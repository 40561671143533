type Props = React.JSX.IntrinsicElements['input'];

export const RadioInput: React.FC<Props> = ({ ...rest }: Props) => {
    return (
        <div className={'grid w-max place-items-center'}>
            <input
                className="peer col-start-1 row-start-1 size-4 shrink-0 appearance-none rounded-full border-2 border-brand disabled:border-gray-400"
                {...rest}
            />
            <div className="pointer-events-none col-start-1 row-start-1 size-2 rounded-full peer-checked:bg-brand peer-checked:peer-disabled:bg-gray-400" />
        </div>
    );
};
