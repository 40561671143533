import {
    faPlay,
    faPause,
    faStop,
    faChevronUp,
    faChevronDown,
    faRotateLeft,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { classNames } from '@framework/utils';
import { TimeDisplayContainer } from './TimeDisplayContainer';
import { TimeStepButton } from './TimerStepButton';
import { useUserType } from '@framework/auth';

// 残り時間未設定は再生ボタンを無効化
const isPlayButtonDisabled = (totalSeconds: number, isRunning: boolean, isExpired: boolean): boolean => {
    return totalSeconds === 0 && !isRunning && !isExpired;
};

// タイマー実行中、またはタイマー未設定時はリセットボタンを無効化
const isResetButtonDisabled = (totalSeconds: number, isRunning: boolean, isExpired: boolean): boolean => {
    return isRunning || (totalSeconds === 0 && !isExpired);
};

type Props = {
    totalSeconds: number;
    isRunning: boolean;
    isExpired: boolean;
    onControlTimer(): void;
    onResetTimer(): void;
    onIncreaseTime(): void;
    onDecreaseTime(): void;
    onUpdateTime(newTotalSeconds: number): void;
    onClose(): void;
};

export const Timer: React.FC<Props> = ({
    totalSeconds,
    isRunning,
    isExpired,
    onControlTimer,
    onResetTimer,
    onIncreaseTime,
    onDecreaseTime,
    onUpdateTime,
    onClose,
}: Props) => {
    const [isEditingMinutes, setIsEditingMinutes] = useState(false);
    const [isEditingSeconds, setIsEditingSeconds] = useState(false);
    const userType = useUserType();
    const isAnonymousUser = useMemo(() => userType === 'anonymous', [userType]);

    const handleMinutesClick = useCallback(() => {
        if (isRunning || isExpired || isAnonymousUser) return;

        setIsEditingMinutes(true);
    }, [isRunning, isExpired, isAnonymousUser]);

    const handleSecondsClick = useCallback(() => {
        if (isRunning || isExpired || isAnonymousUser) return;

        setIsEditingSeconds(true);
    }, [isRunning, isExpired, isAnonymousUser]);

    const handleMinutesBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (isAnonymousUser) return;

        const value = e.target.value;
        const newMinutes = value === '' ? 0 : parseInt(value);
        if (!isNaN(newMinutes) && newMinutes <= 99) {
            const newTotalSeconds = newMinutes * 60 + (totalSeconds % 60);
            onUpdateTime(newTotalSeconds);
        }
        setIsEditingMinutes(false);
    };

    const handleSecondsBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (isAnonymousUser) return;

        const value = e.target.value;
        const newSeconds = value === '' ? 0 : parseInt(value);
        if (!isNaN(newSeconds) && newSeconds <= 59) {
            const newTotalSeconds = newSeconds + totalSeconds - (totalSeconds % 60);
            onUpdateTime(newTotalSeconds);
        }
        setIsEditingSeconds(false);
    };

    return (
        <div className="relative flex h-14 items-center rounded-lg bg-white py-2 shadow">
            {isAnonymousUser ? (
                <TimeDisplayContainer totalSeconds={totalSeconds} isExpired={isExpired} isEditable={false} />
            ) : (
                <>
                    <button
                        onClick={onControlTimer}
                        className={classNames(
                            'ml-4 mr-2 flex size-10 shrink-0 items-center justify-center rounded-full text-2xl focus:outline-none',
                            isExpired || isRunning ? 'bg-gray-400 text-brand' : 'bg-brand text-white',
                            isPlayButtonDisabled(totalSeconds, isRunning, isExpired) && 'disabled:bg-gray-500'
                        )}
                        disabled={isPlayButtonDisabled(totalSeconds, isRunning, isExpired)}
                    >
                        {isExpired ? (
                            <FontAwesomeIcon icon={faStop} />
                        ) : isRunning ? (
                            <FontAwesomeIcon icon={faPause} />
                        ) : (
                            <FontAwesomeIcon icon={faPlay} />
                        )}
                    </button>
                    <div className="flex flex-col items-center">
                        <TimeStepButton icon={faChevronUp} onClick={onIncreaseTime} disabled={isRunning || isExpired} />
                        <TimeStepButton
                            icon={faChevronDown}
                            onClick={onDecreaseTime}
                            disabled={isRunning || isExpired}
                        />
                    </div>
                    <TimeDisplayContainer
                        totalSeconds={totalSeconds}
                        onMinutesBlur={handleMinutesBlur}
                        onSecondsBlur={handleSecondsBlur}
                        isExpired={isExpired}
                        isEditable={!isAnonymousUser && !isRunning}
                        isEditingMinutes={isEditingMinutes}
                        isEditingSeconds={isEditingSeconds}
                        onMinutesClick={handleMinutesClick}
                        onSecondsClick={handleSecondsClick}
                    />
                    <button
                        onClick={onResetTimer}
                        className="mr-2 size-8 rounded-full bg-gray-200 px-2 text-lg hover:bg-gray-300 focus:outline-none disabled:text-gray-500 disabled:hover:bg-gray-200"
                        disabled={isResetButtonDisabled(totalSeconds, isRunning, isExpired)}
                    >
                        <FontAwesomeIcon icon={faRotateLeft} />
                    </button>
                </>
            )}
            <div className="mx-2">
                <button
                    onClick={onClose}
                    className="absolute right-1 top-1 flex size-4 items-center justify-center rounded-full text-sm hover:bg-gray-200 focus:outline-none"
                >
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
        </div>
    );
};
