import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const styleDefinitions = {
    brand: 'bg-brand hover:bg-brand-dark text-white whitespace-nowrap disabled:bg-brand/50 disabled:cursor-not-allowed',
    primary:
        'bg-blue-500 hover:bg-blue-700 text-white whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed',
    secondary:
        'bg-gray-500 hover:bg-gray-700 text-white whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed',
    danger: 'bg-red-500 hover:bg-red-700 text-white whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed',
    flat: 'hover:bg-gray-200 text-brand border border-solid border-gray-500 whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed',
};

type ButtonTheme = keyof typeof styleDefinitions;
type ButtonSize = 'sm' | 'md' | 'lg';

const sizeDefinitions: { [key in ButtonSize]: string } = {
    sm: 'py-1 px-2 text-sm',
    md: 'py-2 px-4 font-bold',
    lg: 'py-2 px-6 text-lg font-bold',
};

type Props = {
    children: React.ReactNode;
    color?: ButtonTheme;
    size?: ButtonSize;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    className?: string;
};

export const Button: React.FC<Props & React.ComponentPropsWithRef<'button'>> = ({
    onClick,
    size = 'md',
    color = 'primary',
    disabled = false,
    loading = false,
    children,
    className,
    ...rest
}: Props & React.ComponentPropsWithRef<'button'>) => {
    return (
        <button
            onClick={onClick}
            className={[styleDefinitions[color], sizeDefinitions[size], 'rounded', className || ''].join(' ')}
            disabled={disabled || loading}
            {...rest}
        >
            {loading && (
                <span className={size === 'sm' ? 'pr-1' : 'pr-2'}>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </span>
            )}
            {children}
        </button>
    );
};
