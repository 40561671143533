import { useCallback, useEffect, useState } from 'react';
import { RTDBPath, usePersistedObject } from '@framework/repository';
import { BannerAnnouncement } from './BannerAnnouncement';
import { CookieUtils } from '@framework/CookieUtils';

type MarkAsRead = () => void;
type Result = [BannerAnnouncement | null, MarkAsRead];

/**
 * バナーお知らせの情報と、それを既読化する関数を返すカスタムフックです。
 * 既読化されたバナーお知らせは、１時間の間は再表示されません。
 */
export const useBannerAnnouncement = (): Result => {
    const [announcement] = usePersistedObject(BannerAnnouncement, RTDBPath.Notification.bannerAnnouncementPath());
    const [value, setValue] = useState<BannerAnnouncement | null>(null);

    const markAsRead = useCallback(() => {
        if (!announcement) return;

        CookieUtils.set(announcement.cookieKey, 'true', {
            expires: 1 / 24, // 1時間
        });

        setValue(null);
    }, [announcement]);

    useEffect(() => {
        if (!announcement) {
            setValue(null);
            return;
        }

        if (CookieUtils.get(announcement.cookieKey)) {
            setValue(null);
            return;
        }

        setValue(announcement);
    }, [announcement]);

    return [value, markAsRead];
};
