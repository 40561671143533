export interface IpRestrictionConfiguration {
    policy: IpRestrictionPolicy;
    allowRules?: IpRestrictionRule[]; // NOTE: RTDBに空の配列を保存するとプロパティごと消えるのでoptional
}

export const defaultIpRestrictionConfiguration: IpRestrictionConfiguration = {
    policy: 'none',
    allowRules: [],
};

type IpRestrictionPolicy = 'allow_only' | 'none';

interface IpRestrictionRule {
    cidr: string;
    memo: string;
}
