import { ActionLogEventParamsContextProvider } from '@framework/action-log';
import { FullScreenLoading } from '@framework/ui';
import { useParams } from 'react-router-dom';
import { NotFoundPage } from '../NotFoundPage';
import { ViewModelPage } from './ViewModelPage';
import { useViewModelEntity } from '@view-model/domain/view-model';
import { UserOperationModesProvider } from './UserOperationModesProvider';

/**
 * `/view-models/<id>` 形式のURLでアクセスしたときに、
 * ビューモデルのページを表示するために必要な情報を取得・準備するラッパーコンポーネントです。
 *
 * @returns
 */
export const ViewModelPageRouteContent: React.FC = () => {
    const { viewModelId } = useParams<{ viewModelId: string }>();
    const { viewModel, workspace, loading } = useViewModelEntity(viewModelId);

    if (loading) {
        return <FullScreenLoading />;
    }

    if (!workspace || !viewModel) {
        return <NotFoundPage />;
    }

    return (
        <ActionLogEventParamsContextProvider
            groupId={viewModel.groupId}
            workspaceId={workspace.id}
            workspaceName={workspace.name.toString()}
            viewModelId={viewModelId}
            viewModelName={viewModel.name.toString()}
        >
            <UserOperationModesProvider>
                <ViewModelPage workspace={workspace} viewModel={viewModel} />
            </UserOperationModesProvider>
        </ActionLogEventParamsContextProvider>
    );
};
