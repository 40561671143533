import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { BuildInfo } from '@config/build-info';
import { useActionLogSender } from '@framework/action-log';
import { RTDBPath, RefBuilder, usePrimitiveValueListener } from '@framework/repository';
import { CookieUtils } from '../CookieUtils';

const CookieKey = 'Balus-Reloaded';

type Props = {
    children: ReactElement;
};

export const WithReloadRequiredVersion: React.FC<Props> = ({ children }: Props) => {
    const [requiredVersion] = usePrimitiveValueListener<number | null>(
        RTDBPath.ApplicationConfig.requiredVersionPath()
    );

    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const rootNode = useRef<HTMLElement>(document.querySelector('#root'));
    const logSender = useActionLogSender();

    const reload = useCallback(async () => {
        await logSender('global:reload', {
            cause: 'required_version',
        });

        setLoading(true);
        window.location.reload();
    }, [logSender]);

    useEffect(() => {
        // 要求バージョンの条件を満たした場合には再読み込みモーダルを非表示化する
        if (BuildInfo.isSatisfied(requiredVersion)) {
            setShowModal(false);
            return;
        }

        if (CookieUtils.get(CookieKey)) {
            setShowModal(true);

            logSender('global:show_reload_modal', {
                currentVersion: BuildInfo.version,
                requiredVersion: requiredVersion || 0,
            });
        } else {
            // 複数のタブを開いている場合にそれぞれのタブで再読み込みが発生するタイミングをずらす
            setTimeout(() => {
                // リロードの無限ループを防ぐために Cookie を設定してから再読み込みを行う (Cookie の有効期限は 5 分間)
                CookieUtils.set(CookieKey, 'true', { expires: 5 / (24 * 60) });
                reload();
            }, Math.random() * 1000);
        }
    }, [logSender, reload, requiredVersion]);

    useEffect(() => {
        // ブラウザのタブがアクティブに変化したときに、要求バージョンの条件を再取得・再確認する
        const handleOnActive = async () => {
            if (document.visibilityState !== 'visible') {
                return;
            }

            const snapshot = await RefBuilder.ref(RTDBPath.ApplicationConfig.requiredVersionPath()).get();
            const value = snapshot.val();
            if (BuildInfo.isSatisfied(value)) {
                setShowModal(false);
                return;
            }

            reload();
        };

        if (rootNode.current) {
            rootNode.current.addEventListener('visibilitychange', handleOnActive);
        }
    }, [reload]);

    return showModal ? (
        <GeneralModal
            isOpen={true}
            onClose={() => void 0}
            title={'再読み込みが必要です'}
            submitButton={<></>}
            cancelButton={<></>}
            shouldCloseOnOverlayClick={false}
        >
            <div className="flex flex-col p-4">
                <div className="py-2 text-center">Balusの利用を継続するには、再読み込みが必要です。</div>
                <div className="py-2 text-center">
                    <Button onClick={reload} loading={loading}>
                        再読み込み
                    </Button>
                </div>
            </div>
        </GeneralModal>
    ) : (
        children
    );
};
